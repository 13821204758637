import React, { useEffect} from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestTypes } from '../redux/agent/actionCreator';


const { SubMenu } = Menu;
function MenuItems({ darkMode, toggleCollapsed, topMenu }) {
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const fullPath = pathName.split('/');
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');
    const [openKeys, setOpenKeys] = React.useState(
        !topMenu ? [`${mainPathSplit.length > 3 ? mainPathSplit[1] : 'dashboard'}`] : [],
    );

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    const onClick = (item) => {
        if (item.keyPath.length === 1) setOpenKeys([]);
    };

    const loginDetails = useSelector(state => state.auth.loginData);
    const agentStatus = useSelector((state) => state.agentStatus.data);
   
    const dispatch = useDispatch();

    useEffect(()=> {
        dispatch(getRequestTypes())
    },[dispatch])

    const getRequestTypesDetails = useSelector((state) => state.getRequestTypes.data);

    const typevalue = []; //'complaint-register', 'demo-register'

    return (
        <>
            <p className="sidebar-nav-title">{ fullPath[2] } MENU </p>
            <Menu onOpenChange={onOpenChange} onClick={onClick} mode={'inline'} theme={darkMode && 'dark'}
                // // eslint-disable-next-line no-nested-ternary
                defaultSelectedKeys={
                    !topMenu
                    ? [
                        `${
                            mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                        }`,
                        ]
                    : []
                }
                defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
                overflowedIndicator={<FeatherIcon icon="more-vertical" />}
                openKeys={openKeys}
            >
               
                { fullPath[2] === 'sales' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/sales_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="sales_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/sales_list`}>
                                Sales List
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'customer' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/customer_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="customer_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/customer_list`}>
                                Customers
                            </NavLink>
                        </Menu.Item>

                        {/* <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/customer_group`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="customer_group"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/customer_group`}>
                                Customers Group
                            </NavLink>
                        </Menu.Item> */}

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'employee' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        <SubMenu key="profile" icon={!topMenu && <FeatherIcon icon="users" />} title="Employee">
                            <Menu.Item key="employee_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/employee_list`}>
                                    Employee List
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="isd_employee_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/isd_employee_list`}>
                                    ISD Employee List
                                </NavLink>
                            </Menu.Item>
                            {loginDetails?.loginType && loginDetails?.loginType === 'superadmin' && (
                            <Menu.Item key="custom_employee_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/custom_employee_list`}>
                                    Custom Employee
                                </NavLink>
                            </Menu.Item>
                            )}
                        </SubMenu>
                        {/* <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/employee_control`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="employee_control"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/employee_control`}>
                                Employee Control
                            </NavLink>
                        </Menu.Item> */}
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="flag" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="flag" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
        
                    </>
                )}

                { fullPath[2] === 'store' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/store_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="store_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/store_list`}>
                                Store List
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'inventory' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/inventory_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="inventory_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/inventory_list`}>
                                Inventory List
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/inventory_request`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="inventory_request"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/inventory_request`}>
                                Inventory Request
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/search_product`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="search_product"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/search_product`}>
                                Search Product
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'walkout' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="happy_data_list" icon={!topMenu && <FeatherIcon icon="list" />} title="Walkout Data" class="parent">
                            <Menu.Item key="un_assign_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/un_assign_list`}>
                                    Un-Assign Walkout List
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="assign_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/assign_list`}>
                                    Assigned Walkout List
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="close_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/close_list`}>
                                    Closed List
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                    All Walkout List
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>
                        
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/category`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="category"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/category`}>
                                Categories
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                Walkout Customer
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'datamanagement' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="register_data_list" icon={!topMenu && <FeatherIcon icon="air play" />} title="Register Data List" class="parent">
                            <Menu.Item key={'sap'}>
                                <NavLink onClick={toggleCollapsed} to={{ pathname:`${path}/demo_register/sap`}}  >
                                    Demo Register SAP
                                </NavLink>
                            </Menu.Item>
                            { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0  && getRequestTypesDetails.object_list.map((value,key) => (value.menu_type === 'register' && !typevalue.includes(value.slug) ) && (
                            <Menu.Item key={value.slug}>
                                <NavLink onClick={toggleCollapsed} to={{ pathname:`${path}/follow/${value.slug}`, state:value}}  >
                                    {value.title}
                                </NavLink>  
                            </Menu.Item>
                            ))}
                        </SubMenu>
                    
                        
                        <SubMenu key="happy_data_list" icon={!topMenu && <FeatherIcon icon="air play" />} title="Happy Data List" class="parent">
                            <Menu.Item key="demo_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/demo_happy`}>
                                    Demo Happy
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="demo_happy_sap">
                                <NavLink onClick={toggleCollapsed} to={`${path}/demo_happy_sap`}>
                                    Demo Happy - SAP
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="complaint_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/complaint_happy`}>
                                    Complaint Happy
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="furniture_complaint_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/furniture_complaint_happy`}>
                                    Furniture Complaint Happy
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="follow_up_list" icon={!topMenu && <FeatherIcon icon="air play" />} title="Follow up List" class="parent">
                            

                            <SubMenu key="scheduled-log " icon={!topMenu && <FeatherIcon icon="list" />} title="Scheduled Log ">
                                <Menu.Item key="schedule_log_complaint-register">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/complaint-register`}>
                                    Complaint
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="schedule_log_demo-register">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/demo-register`}>
                                    Demo
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="sap_schedule_log">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/sap_schedule_log`}>
                                    Demo SAP
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="schedule_log_furniture-complaint-register">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/furniture-complaint-register`}>
                                    Furniture Complaint 
                                    </NavLink>  
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu key="priority-log " icon={!topMenu && <FeatherIcon icon="list" />} title="Priority Log ">
                                <Menu.Item key="priority_log_complaint-register">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/priority_log/complaint-register`}>
                                    Complaint
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="priority_log_demo-register">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/priority_log/demo-register`}>
                                    Demo
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="sap_priority_log">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/sap_priority_log`}>
                                    Demo SAP
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="priority_log_furniture-complaint-register">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/priority_log/furniture-complaint-register`}>
                                    Furniture Complaint 
                                    </NavLink>  
                                </Menu.Item>
                            </SubMenu>
                        
                            
                        </SubMenu>

                        <SubMenu key="tele_sales_tab" icon={!topMenu && <FeatherIcon icon="air play" />} title="Tele Sales Tab" class="parent">
                            
                            { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0  && getRequestTypesDetails.object_list.map((value,key) => value.menu_type === 'tele-sales' && (
                                <Menu.Item key={value.slug}>
                                    <NavLink onClick={toggleCollapsed}  to={{ pathname:`${path}/follow/${value.slug}`, state:value}}>
                                        {value.title}
                                    </NavLink>  
                                </Menu.Item>
                            ))}

                            <Menu.Item key="accessories_tele_sales_sap">
                                <NavLink onClick={toggleCollapsed} to={`${path}/accessories_tele_sales_sap`}>
                                Accessories Tele Sales - SAP
                                </NavLink>  
                            </Menu.Item>

                            <Menu.Item key="mandatory_service">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mandatory_service`}>
                                    PAI Mandatory Service
                                </NavLink>  
                            </Menu.Item>

                            <Menu.Item key="abandoned_cart">
                                <NavLink onClick={toggleCollapsed} to={`${path}/abandoned_cart`}>
                                    Abandoned Cart
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="upload-dump" icon={!topMenu && <FeatherIcon icon="list" />} title="Upload Dump">
                            <Menu.Item key="upload_dump">
                                <NavLink onClick={toggleCollapsed} to={`${path}/upload_dump`}>
                                    Upload
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="upload_dump_status">
                                <NavLink onClick={toggleCollapsed} to={`${path}/upload_dump_status`}>
                                Upload Status
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report 
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'queries' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/purchase`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="purchase"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/purchase`}>
                                Purchase Queries
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/purchase_follow`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="purchase_follow"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/purchase_follow`}>
                                Purchase Queries
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'customercare' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="call_center_report" icon={!topMenu && <FeatherIcon icon="list" />} title="Call Center Report">    
                            <SubMenu key="lead" icon={!topMenu && <FeatherIcon icon="list" />} title="Live Calls">
                                <Menu.Item key="live_calls_self">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/live_calls_self`}>
                                        Marketing
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="live_calls_blr_sip">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/live_calls_blr_sip`}>
                                    BLR SIP
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="signin_signout">
                                <NavLink onClick={toggleCollapsed} to={`${path}/signin_signout`}>
                                    SignIn SignOut
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="hours_activity_report">
                                <NavLink onClick={toggleCollapsed} to={`${path}/hours_activity_report`}>
                                    Hours Activity Report
                                </NavLink>
                            </Menu.Item>

                            <SubMenu key="lead" icon={!topMenu && <FeatherIcon icon="list" />} title="Call Mointoring Report">
                                <Menu.Item key="call_report_purchase">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/call_report/purchase`}>
                                        Purchase Enquiry
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="call_report_complaint">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/call_report/complaint`}>
                                    Complaint
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="call_report_manual">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/call_report/manual`}>
                                    Manual
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="call_report_walkout">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/call_report/walkout`}>
                                    Walkout
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>


                            <Menu.Item key="call_barging_report">
                                <NavLink onClick={toggleCollapsed} to={`${path}/call_barging_report`}>
                                    Call Barging Report
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="voip_incoming_call">
                                <NavLink onClick={toggleCollapsed} to={`${path}/voip_incoming_call`}>
                                    VOIP Incoming Calls
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="agent">
                            <NavLink onClick={toggleCollapsed} to={`${path}/agent`}>
                                Agent
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="setting" icon={!topMenu && <FeatherIcon icon="settings" />} title="Setting">
                            <Menu.Item key="setting_request_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/setting_request_type`}>
                                    Request Type
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="setting_sub_request_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/setting_sub_request_type`}>
                                    Sub Request Type
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="setting_break_details">
                                <NavLink onClick={toggleCollapsed} to={`${path}/setting_break_details`}>
                                    Break Details
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'maintenance' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="list">
                            <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                List
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="follow">
                            <NavLink onClick={toggleCollapsed} to={`${path}/follow`}>
                                Follow
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="employee">
                            <NavLink onClick={toggleCollapsed} to={`${path}/employee`}>
                                Maintenance Employee
                            </NavLink>
                        </Menu.Item>

                        {/* <SubMenu key="setting" icon={!topMenu && <FeatherIcon icon="settings" />} title="Setting">
                            <Menu.Item key="mt_department">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_department`}>
                                    Department
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="mt_issues_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_issues_type`}>
                                    Type of Issues
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu> */}

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'defective' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="follow_defective-unit-delivered">
                            <NavLink onClick={toggleCollapsed} to={`${path}/follow/defective-unit-delivered`}>
                                Complaint Register
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="defective_unit_delivered_happy">
                            <NavLink onClick={toggleCollapsed} to={`${path}/defective_unit_delivered_happy`}>
                                Happy
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="priority_log_defective-unit-delivered">
                            <NavLink onClick={toggleCollapsed} to={`${path}/priority_log/defective-unit-delivered`}>
                                Priority
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="schedule_log_defective-unit-delivered">
                            <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/defective-unit-delivered`}>
                                Scheduled
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'legal' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="follow_legal-case">
                            <NavLink onClick={toggleCollapsed} to={`${path}/follow/legal-case`}>
                                Complaint Register
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="legal_case_happy">
                            <NavLink onClick={toggleCollapsed} to={`${path}/legal_case_happy`}>
                                Happy
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="priority_log_legal_case_log">
                            <NavLink onClick={toggleCollapsed} to={`${path}/legal_case_log`}>
                                Priority
                            </NavLink>
                        </Menu.Item>

                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="schedule_log_defective-unit-delivered">
                            <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/defective-unit-delivered`}>
                                Scheduled
                            </NavLink>
                        </Menu.Item> */}

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'quality' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="list">
                            <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                Quality Leads
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="mt_tickets">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_tickets`}>
                                Quality Leads
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="setting" icon={!topMenu && <FeatherIcon icon="settings" />} title="Setting">
                            <Menu.Item key="mt_department">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_department`}>
                                    Department
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="mt_issues_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_issues_type`}>
                                    Type of Issues
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'warranty' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="purchase">
                            <NavLink onClick={toggleCollapsed} to={`${path}/purchase`}>
                                Purchase
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="complaint">
                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint`}>
                                Complaint Register
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="complaint_happy">
                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint_happy`}>
                                Happy
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="priority">
                            <NavLink onClick={toggleCollapsed} to={`${path}/priority`}>
                                Priority
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="scheduled">
                            <NavLink onClick={toggleCollapsed} to={`${path}/scheduled`}>
                                Scheduled
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'out_of_warranty' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="complaint">
                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint`}>
                                Complaint Register
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="complaint_happy">
                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint_happy`}>
                                Happy
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="priority">
                            <NavLink onClick={toggleCollapsed} to={`${path}/priority`}>
                                Priority
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="scheduled">
                            <NavLink onClick={toggleCollapsed} to={`${path}/scheduled`}>
                                Scheduled
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'escalation' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="follow_legal-case">
                            <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                Escalation
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="scheduled">
                            <NavLink onClick={toggleCollapsed} to={`${path}/scheduled`}>
                                Scheduled
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="priority">
                            <NavLink onClick={toggleCollapsed} to={`${path}/priority`}>
                                Priority
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'review' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        
                        <SubMenu key="medium" icon={!topMenu && <FeatherIcon icon="list" />} title="medium">
                            <Menu.Item key="medium_google">
                                <NavLink onClick={toggleCollapsed} to={`${path}/medium/google`}>
                                    Google
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="medium_facebook">
                                <NavLink onClick={toggleCollapsed} to={`${path}/medium/facebook`}>
                                    Facebook
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="medium_instagram">
                                <NavLink onClick={toggleCollapsed} to={`${path}/medium/instagram`}>
                                    Instagram
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="follow" icon={!topMenu && <FeatherIcon icon="globe" />} title="follow">
                            <Menu.Item key="follow_purchase-experience">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow/purchase-experience`}>
                                    Purchase Experience
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="follow_delivery-experience">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow/delivery-experience`}>
                                Delivery Experience
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="follow_service-experience">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow/service-experience`}>
                                Service Experience
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>
                        
                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'stock_defective' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="list">
                            <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                Registered List
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="pending_list">
                            <NavLink onClick={toggleCollapsed} to={`${path}/pending_list`}>
                                Pending List
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="upload_dump" icon={!topMenu && <FeatherIcon icon="globe" />} title="Upload Dump">
                            <Menu.Item key="upload_dump">
                                <NavLink onClick={toggleCollapsed} to={`${path}/upload_dump`}>
                                    Upload
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="upload_dump_status">
                                <NavLink onClick={toggleCollapsed} to={`${path}/upload_dump_status`}>
                                    Upload Status
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}
                
                { fullPath[2] === 'agent' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        {agentStatus && agentStatus?.current_status === 'Available'  && (
                            <>
                                <Menu.Item icon={!topMenu && <FeatherIcon icon="search" />} key="check_customer">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/check_customer`}>
                                        Search Customer
                                    </NavLink>
                                </Menu.Item>

                                <Menu.Item icon={!topMenu && <FeatherIcon icon="search" />} key="search_reference">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/search_reference`}>
                                        Search Referenece No
                                    </NavLink>
                                </Menu.Item>

                                <SubMenu key="purchase_enquiry_parent" icon={!topMenu && <FeatherIcon icon="globe" />} title="Purchase Enquiry">
                                    <Menu.Item key="assign_leads_purchase">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/assign_leads_purchase`}>
                                            Purchase List
                                        </NavLink>  
                                    </Menu.Item>
                                    <Menu.Item key="purchase_enquiry">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/purchase_enquiry`}>
                                            Add Purchase Enquiry
                                        </NavLink>  
                                    </Menu.Item>
                                </SubMenu>


                                <SubMenu key="review" icon={!topMenu && <FeatherIcon icon="star" />} title="Review">
                                    <Menu.Item key="add_review">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/add_review`}>
                                        Add Review
                                        </NavLink>  
                                    </Menu.Item>
                                    <Menu.Item key="review_list">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/review_list`}>
                                        Review List
                                        </NavLink>  
                                    </Menu.Item>
                                </SubMenu>

                                {agentStatus?.lead_type === 'assign_leads'  && (

                                    <SubMenu key="assign_leads" icon={!topMenu && <FeatherIcon icon="air play" />} title="Assign Leads">
                                        <Menu.Item key="assign_leads_walkout">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/assign_leads_walkout`}>
                                                Walkout Leads
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="pai_mandatory_service">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/pai_mandatory_service`}>
                                                PAI Mandatory Service
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="abandoned_cart">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/abandoned_cart`}>
                                                Abandoned Cart
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="abandoned_cart_priority">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/abandoned_cart_priority`}>
                                                Abandoned Cart Priority
                                            </NavLink>  
                                        </Menu.Item>

                                        <Menu.Item key="accessories_tele_sales_sap">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/accessories_tele_sales_sap`}>
                                                Accessories Tele Sales - SAP
                                            </NavLink>  
                                        </Menu.Item>


                                        {getRequestTypesDetails && getRequestTypesDetails.object_list && getRequestTypesDetails.object_list.length > 0 && getRequestTypesDetails.object_list.map((value, key) =>  value.type === 'assign-leads' && (
                                            <Menu.Item key={key} >
                                                <NavLink onClick={toggleCollapsed} to={{pathname:`${path}/follow/${value.slug}`, state: value  }}>
                                                    {value.title}
                                                </NavLink>  
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                    
                                )}

                                

                                {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="phone-call" />} key="change_call">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/change_call`}>
                                        Change Call
                                    </NavLink>
                                </Menu.Item> */}

                                {agentStatus?.lead_type === 'dial_out'  && (

                                    <SubMenu key="dial_out" icon={!topMenu && <FeatherIcon icon="aperture" />} title="Dial Out">
                                         
                                         <SubMenu key="complaint_register" icon={!topMenu && <FeatherIcon icon="aperture" />} title="Complaint Register  ">
                                            {getRequestTypesDetails && getRequestTypesDetails.object_list && getRequestTypesDetails.object_list.length > 0 && getRequestTypesDetails.object_list.map((value, key) =>  value.type === 'dail-out' && (
                                                <Menu.Item key={key} >
                                                    <NavLink onClick={toggleCollapsed} to={{pathname:`${path}/follow/${value.slug}`, state: value  }}>
                                                        {value.title}
                                                    </NavLink>  
                                                </Menu.Item>
                                            ))}
                                        </SubMenu>
                                         
                                        <SubMenu key="happy" icon={!topMenu && <FeatherIcon icon="list" />} title="Happy">
                                            <Menu.Item key="demo_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/demo_happy`}>
                                                    Demo Happy
                                                </NavLink>  
                                            </Menu.Item>
                                            <Menu.Item key="demo_happy_sap">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/demo_happy_sap`}>
                                                    Demo Happy - SAP
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="complaint_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/complaint_happy`}>
                                                    Complaint Happy
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="furniture_complaint_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/furniture_complaint_happy`}>
                                                    Furniture Complaint Happy
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="legal_case_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/legal_case_happy`}>
                                                    Legal Case Happy
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="stock_defective_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/stock_defective_happy`}>
                                                Stock Set Defective
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="defective_unit_delivered_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/defective_unit_delivered_happy`}>
                                                    Defective Unit Delivered
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="escalation_happy">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/escalation_happy`}>
                                                    Escalation
                                                </NavLink>  
                                            </Menu.Item>
                                        </SubMenu>
                                        

                                        <SubMenu key="scheduled_log" icon={!topMenu && <FeatherIcon icon="list" />} title="Scheduled Log">
                                            <Menu.Item key="schedule_log_complaint-register">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/complaint-register`}>
                                                    Complaint
                                                </NavLink>  
                                            </Menu.Item>
                                            <Menu.Item key="schedule_log_demo-register">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/demo-register`}>
                                                    Demo
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="sap_schedule_log">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/sap_schedule_log`}>
                                                    Demo - SAP
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="schedule_log_furniture-complaint-register">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log_furniture-complaint-register`}>
                                                    Furniture Complaint
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="schedule_log_defective-unit-delivered">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log/defective-unit-delivered`}>
                                                    Defective Unit Delivered
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="stock_defective_schedule_log">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/stock_defective_schedule_log`}>
                                                    Stock Set Defective
                                                </NavLink>  
                                            </Menu.Item>

                                        </SubMenu>


                                        <SubMenu key="priority_log" icon={!topMenu && <FeatherIcon icon="list" />} title="Happy">
                                            <Menu.Item key="priority_log_complaint-register">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/priority_log/complaint-register`}>
                                                    Complaint 
                                                </NavLink>  
                                            </Menu.Item>
                                            <Menu.Item key="priority_log_demo-register">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/priority_log/demo-register`}>
                                                    Demo
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="sap_priority_log">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/sap_priority_log`}>
                                                    Demo - SAP
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="priority_log_furniture_complaint_register">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/priority_log_furniture_complaint_register`}>
                                                    Furniture Complaint 
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="stock_defective_priority_log">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/stock_defective_priority_log`}>
                                                 Stock Set Defective
                                                </NavLink>  
                                            </Menu.Item>

                                            <Menu.Item key="priority_log_defective-unit-delivered">
                                                <NavLink onClick={toggleCollapsed} to={`${path}/priority_log_defective-unit-delivered`}>
                                                    Defective Unit Delivered
                                                </NavLink>  
                                            </Menu.Item>
                                            
                                        </SubMenu>
                                    </SubMenu>
                                       
                                )}

                                <Menu.Item icon={!topMenu && <FeatherIcon icon="phone-call" />} key="call_record">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/call_record`}>
                                        Call Record
                                    </NavLink>
                                </Menu.Item>
                            </>
                        )}

                        <Menu.Item icon={ !topMenu && ( 
                            <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                ) } key="report" >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'maintenance_employee' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="mt_list">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_list`}>
                                Open
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="mt_follow">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_follow`}>
                                Follow Up 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>

                    </>
                )}

                { fullPath[2] === 'vendor' && fullPath[3] === 'extended' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="complaint">
                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint`}>
                                Complaint List
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'vendor' && fullPath[3] === 'outofwarranty' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="complaint">
                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint`}>
                                Complaint List
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'profile' &&(
                    <>
                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="user" />} key="profile">
                            <NavLink onClick={toggleCollapsed} to={`${path}/profile`}>
                                My Profile
                            </NavLink>
                        </Menu.Item> */}
                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="settings" />} key="setting">
                            <NavLink onClick={toggleCollapsed} to={`${path}/setting`}>
                                Account Setting
                            </NavLink>
                        </Menu.Item> */}
                        <Menu.Item icon={!topMenu && (<svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-key"
                            >
                            <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                            </svg>)} key="change_password">
                            <NavLink onClick={toggleCollapsed} to={`${path}/change_password`}>
                                Change Password
                            </NavLink>
                        </Menu.Item>
                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="bell" />} key="notification">
                            <NavLink onClick={toggleCollapsed} to={`${path}/notification`}>
                                Notification
                            </NavLink>
                        </Menu.Item> */}
                    </>
                )}

                {/* { fullPath[2] === 'feedback' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/ticket_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="ticket_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/ticket_list`}>
                                Tickets 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )} */}
                
            </Menu>
        </>
    );
}

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
};

export default MenuItems;
