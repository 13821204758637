import actions from './actions';

const { 
    CATEGORY_DATA_BEGIN, CATEGORY_DATA_SUCCESS, CATEGORY_DATA_ERR, 
    BRAND_DATA_BEGIN, BRAND_DATA_SUCCESS, BRAND_DATA_ERR, 
    STORE_LIST_DATA_BEGIN,  STORE_LIST_DATA_SUCCESS, STORE_LIST_DATA_ERR, 
    STORE_PINCODE_DATA_BEGIN, STORE_PINCODE_DATA_SUCCESS, STORE_PINCODE_DATA_ERR, STORE_PINCODE_DATA_RESET,
    MODEL_LIST_DATA_BEGIN, MODEL_LIST_DATA_SUCCESS, MODEL_LIST_DATA_ERR,
    STOCK_EMPLOYEE_LIST_DATA_BEGIN, STOCK_EMPLOYEE_LIST_DATA_SUCCESS, STOCK_EMPLOYEE_LIST_DATA_ERR,
    MAINTENANCE_EMPLOYEE_LIST_DATA_BEGIN, MAINTENANCE_EMPLOYEE_LIST_DATA_SUCCESS, MAINTENANCE_EMPLOYEE_LIST_DATA_ERR,
    VENDOR_EMPLOYEE_LIST_DATA_BEGIN, VENDOR_EMPLOYEE_LIST_DATA_SUCCESS, VENDOR_EMPLOYEE_LIST_DATA_ERR,
    STATE_LIST_DATA_BEGIN, STATE_LIST_DATA_SUCCESS, STATE_LIST_DATA_ERR,
    LANGUAGE_LIST_DATA_BEGIN, LANGUAGE_LIST_DATA_SUCCESS, LANGUAGE_LIST_DATA_ERR,
} = actions;

const initialStateFilter = { data: '', loading: false, error: null, };

const categoryReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CATEGORY_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case CATEGORY_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };

        default:
            return state;
    }
};

const brandReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case BRAND_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case BRAND_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case BRAND_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const branchReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STORE_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case STORE_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case STORE_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const storeListPincodeReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STORE_PINCODE_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case STORE_PINCODE_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case STORE_PINCODE_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
        case STORE_PINCODE_DATA_RESET:
            return {
                ...initialStateFilter,
                data: null,
            };
        default:
            return state;
    }
};

const categoryBrandModelReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MODEL_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case MODEL_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case MODEL_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const stockEmployeeListReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STOCK_EMPLOYEE_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case STOCK_EMPLOYEE_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case STOCK_EMPLOYEE_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const maintenanceEmployeeListReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_EMPLOYEE_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case  MAINTENANCE_EMPLOYEE_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case  MAINTENANCE_EMPLOYEE_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const vendorEmployeeListReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VENDOR_EMPLOYEE_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case VENDOR_EMPLOYEE_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case VENDOR_EMPLOYEE_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const stateListReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case STATE_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case STATE_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case STATE_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};


const languageListReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case LANGUAGE_LIST_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case LANGUAGE_LIST_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error: null,
                loading: false,
            };
        case LANGUAGE_LIST_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data: null,
                loading: false,
            };
            
        default:
            return state;
    }
};

export { 
    categoryReducers, brandReducers, branchReducers, storeListPincodeReducers, categoryBrandModelReducers, stockEmployeeListReducers,
    maintenanceEmployeeListReducers, vendorEmployeeListReducers, stateListReducers, languageListReducers,
};
