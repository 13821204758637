import React, { useCallback, useState } from 'react';
import { Drawer, Input, notification , Form} from 'antd';
import { agentManualClicktocall } from '../../redux/agent/actionCreator';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';

const ManualDialPad = () => {
    const [visible, setVisible] = useState(false);
    
    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();
    const [form] = Form.useForm();
  
    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setInputValue('');
    };

    const handleButtonClick = useCallback((value) => {
        // Prevent input if length is already 10
        if (inputValue.length >= 14) {
            return;
        }
        const newValue = inputValue + value;
        setInputValue(newValue);
        form.setFieldsValue({ customer_number: newValue });
    },[form,inputValue]);

    const handleClear = () => {
        form.resetFields();
        setInputValue('');
    };

    const agentManualClicktoCallStatus = useSelector((state) => state.agentManualClicktoCallStatus);

    const [calltrigger, setCalltrigger] = useState(false);

    const handleSubmit = (value) => {
        dispatch(agentManualClicktocall(value));
        // After successful submission, close the modal
        if(agentManualClicktoCallStatus.error === null){
            notification.success({
                message: 'Call Initiated.....',
            });
            setCalltrigger(true);
            handleClear();
        }
        onClose();
        setCalltrigger(false);
    };

    return (
        <div key="1">
            <Button size="default" type="link"  outlined onClick={showDrawer} >
                <FeatherIcon icon="phone" size={14} /> Maunal Dial
            </Button>
            <Drawer title="Dial Pad" placement="right" onClose={onClose} open={visible}>
                <Form layout="vertical" form={form}  onFinish={handleSubmit}>
                    <Form.Item name="customer_number" label="Enter Phone Number" rules={[
                        { type: 'number', message: 'Enter Phone Number',
                            asyncValidator: (rules, value) => {
                                return new Promise((resolve, reject) => {
                                    if ((!/^[0-9]+$/.test(value))) {
                                        reject();
                                    } else {
                                        resolve();
                                    }
                                });
                            },
                        }, 
                        { required: true, message: 'Enter Phone Number', }, ]} style={{ marginBottom: 16 }}>
                        <Input placeholder="Enter Phone Number" maxLength={14} />
                    </Form.Item>

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((digit) => (
                            <Button type="dashed" outlined key={digit} onClick={() => handleButtonClick(digit.toString())} style={{ width: '31.5%' }} size="large">
                                {digit}
                            </Button>
                        ))}
                    </div>


                    <div style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}>
                        <Button type="danger" onClick={handleClear} outlined style={{ flex: 1,  marginRight: '10px'  }} size="large">
                            Clear
                        </Button>
                        <Button   htmlType="submit" type="primary" outlined style={{ flex: 1,  marginLeft: '10px' }} size="large" disabled={calltrigger} >
                            Call
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </div>
    );
};

export default ManualDialPad;

