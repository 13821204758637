import actions from './actions';
import { DataService } from '../../config/data/dataService';
const { 
    categoryDataBegin, categoryDataSuccess, categoryDataErr, 
    brandDataBegin, brandDataSuccess, brandDataErr,
    storeListDataBegin, storeListDataSuccess, storeListDataErr, 
    storePincodeDataBegin, storePincodeDataSuccess, storePincodeDataErr, storePincodeDataReset,
    modelListDataBegin, modelListDataSuccess, modelListDataErr,  
    stockEmployeeListDateBegin, stockEmployeeListDataSuccess, stockEmployeeListDataErr, 
    maintenanceEmployeeListDateBegin, maintenanceEmployeeListDateSuccess, maintenanceEmployeeListDateErr, 
    vendorEmployeeListDataBegin,vendorEmployeeListDataSuccess,vendorEmployeeListDataErr,
    stateListDataBegin,stateListDataSuccess,stateListDataErr,
    languageListDataBegin,languageListDataSuccess,languageListDataErr,
} = actions;

const getCategoryList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(categoryDataBegin());
            const response = await DataService.get('/getCategoryList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(categoryDataSuccess(response.data.data));
            } else {
                dispatch(categoryDataErr());
            }
        } catch (err) {
            dispatch(categoryDataErr(err));
        }
    };
}

const getNewCategoryList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(categoryDataBegin());
            const response = await DataService.get('/getNewCategoryList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(categoryDataSuccess(response.data.data));
            } else {
                dispatch(categoryDataErr());
            }
        } catch (err) {
            dispatch(categoryDataErr(err));
        }
    };
}

const getBrandList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(brandDataBegin());
            const response = await DataService.get('/getBrandList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(brandDataSuccess(response.data.data));
            } else {
                dispatch(brandDataErr());
            }
        } catch (err) {
            dispatch(brandDataErr(err));
        }
    };
}

const getBranchList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(storeListDataBegin());
            const response = await DataService.get('/getBranchList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(storeListDataSuccess(response.data.data));
            } else {
                dispatch(storeListDataErr());
            }
        } catch (err) {
            dispatch(storeListDataErr(err));
        }
    };
}

const getStorePincode = (data) => {
    return async (dispatch) => {
        try {
            dispatch(storePincodeDataBegin());
            const response = await DataService.post('/getStoreListBasedonPincode',{pincode:data});
            if (response.status === 200 && response.data.status === true) {
                dispatch(storePincodeDataSuccess(response.data.data));
            } else {
                dispatch(storePincodeDataErr());
            }
        } catch (err) {
            dispatch(storePincodeDataErr(err));
        }
    };
}

const resetStorePincode = () => {
    return async (dispatch) => {
        dispatch(storePincodeDataReset());
    }
}

const getModelDetails = (data)  => {
    return async (dispatch) => {
        try {
            dispatch(modelListDataBegin());
            const response = await DataService.post('/getModelListBasedonCategoryBrand',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(modelListDataSuccess(response.data.data));
            } else {
                dispatch(modelListDataErr());
            }
        } catch (err) {
            dispatch(modelListDataErr(err));
        }
    };
}

const getNewModelDetails = (data)  => {
    return async (dispatch) => {
        try {
            dispatch(modelListDataBegin());
            const response = await DataService.post('/getNewModelListBasedonCategoryBrand',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(modelListDataSuccess(response.data.data));
            } else {
                dispatch(modelListDataErr());
            }
        } catch (err) {
            dispatch(modelListDataErr(err));
        }
    };
}

const getStockEmployeeList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(stockEmployeeListDateBegin());
            const response = await DataService.post('/getStockEmployeeList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(stockEmployeeListDataSuccess(response.data.data));
            } else {
                dispatch(stockEmployeeListDataErr());
            }
        } catch (err) {
            dispatch(stockEmployeeListDataErr(err));
        }
    };
}

const getMaintenanceEmployeeList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(maintenanceEmployeeListDateBegin());
            const response = await DataService.post('/getMaintenanceEmployeeList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(maintenanceEmployeeListDateSuccess(response.data.data));
            } else {
                dispatch(maintenanceEmployeeListDateErr());
            }
        } catch (err) {
            dispatch(maintenanceEmployeeListDateErr(err));
        }
    };
}

const getVendorEmployeeList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(vendorEmployeeListDataBegin());
            const response = await DataService.post('/getVendorEmployeeList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(vendorEmployeeListDataSuccess(response.data.data));
            } else {
                dispatch(vendorEmployeeListDataErr());
            }
        } catch (err) {
            dispatch(vendorEmployeeListDataErr(err));
        }
    };
}

const getStateList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(stateListDataBegin());
            const response = await DataService.post('/getStateList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(stateListDataSuccess(response.data.data));
            } else {
                dispatch(stateListDataErr());
            }
        } catch (err) {
            dispatch(stateListDataErr(err));
        }
    };
}


const getLanguageList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(languageListDataBegin());
            const response = await DataService.post('/getLanguageList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(languageListDataSuccess(response.data.data));
            } else {
                dispatch(languageListDataErr());
            }
        } catch (err) {
            dispatch(languageListDataErr(err));
        }
    };
}

export { 
    getCategoryList, getNewCategoryList, 
    getBrandList, getBranchList, getStorePincode, resetStorePincode,  
    getModelDetails, getNewModelDetails, getStockEmployeeList, 
    getMaintenanceEmployeeList, getVendorEmployeeList, getStateList, getLanguageList,
};
