const actions = {

    WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    WALKOUT_DASHBOARD_CATEGORY_DATA_BEGIN : 'WALKOUT_DASHBOARD_CATEGORY_DATA_BEGIN',
    WALKOUT_DASHBOARD_CATEGORY_DATA_SUCCESS: 'WALKOUT_DASHBOARD_CATEGORY_DATA_SUCCESS',
    WALKOUT_DASHBOARD_CATEGORY_DATA_ERR : 'WALKOUT_DASHBOARD_CATEGORY_DATA_ERR',

    WALKOUT_DASHBOARD_TICKET_DATA_BEGIN : 'WALKOUT_DASHBOARD_TICKET_DATA_BEGIN',
    WALKOUT_DASHBOARD_TICKET_DATA_SUCCESS: 'WALKOUT_DASHBOARD_TICKET_DATA_SUCCESS',
    WALKOUT_DASHBOARD_TICKET_DATA_ERR : 'WALKOUT_DASHBOARD_TICKET_DATA_ERR',

    WALKOUT_DASHBOARD_SUMMARY_DATA_BEGIN : 'WALKOUT_DASHBOARD_SUMMARY_DATA_BEGIN',
    WALKOUT_DASHBOARD_SUMMARY_DATA_SUCCESS: 'WALKOUT_DASHBOARD_SUMMARY_DATA_SUCCESS',
    WALKOUT_DASHBOARD_SUMMARY_DATA_ERR : 'WALKOUT_DASHBOARD_SUMMARY_DATA_ERR',

    WALKOUT_DASHBOARD_REGION_DATA_BEGIN : 'WALKOUT_DASHBOARD_REGION_DATA_BEGIN',
    WALKOUT_DASHBOARD_REGION_DATA_SUCCESS: 'WALKOUT_DASHBOARD_REGION_DATA_SUCCESS',
    WALKOUT_DASHBOARD_REGION_DATA_ERR : 'WALKOUT_DASHBOARD_REGION_DATA_ERR',
    
    WALKOUT_QUERIES_DATA_BEGIN : 'WALKOUT_QUERIES_DATA_BEGIN',
    WALKOUT_QUERIES_DATA_SUCCESS: 'WALKOUT_QUERIES_DATA_SUCCESS',
    WALKOUT_QUERIES_DATA_ERR : 'WALKOUT_QUERIES_DATA_ERR',

    WALKOUT_QUERIES_STATUS_DATA_BEGIN : 'WALKOUT_QUERIES_STATUS_DATA_BEGIN', 
    WALKOUT_QUERIES_STATUS_DATA_SUCCESS: 'WALKOUT_QUERIES_STATUS_DATA_SUCCESS', 
    WALKOUT_QUERIES_STATUS_DATA_ERR : 'WALKOUT_QUERIES_STATUS_DATA_ERR', 
    WALKOUT_QUERIES_STATUS_DATA_RESET: 'WALKOUT_QUERIES_STATUS_DATA_RESET',


    WALKOUT_REASON_DATA_BEGIN : 'WALKOUT_REASON_DATA_BEGIN',
    WALKOUT_REASON_DATA_SUCCESS: 'WALKOUT_REASON_DATA_SUCCESS',
    WALKOUT_REASON_DATA_ERR : 'WALKOUT_REASON_DATA_ERR',


    WALKOUT_HISTORY_DATA_BEGIN : 'WALKOUT_HISTORY_DATA_BEGIN',
    WALKOUT_HISTORY_DATA_SUCCESS: 'WALKOUT_HISTORY_DATA_SUCCESS',
    WALKOUT_HISTORY_DATA_ERR : 'WALKOUT_HISTORY_DATA_ERR',

    WALKOUT_DISPOSITION_DATA_BEGIN : 'WALKOUT_DISPOSITION_DATA_BEGIN',
    WALKOUT_DISPOSITION_DATA_SUCCESS: 'WALKOUT_DISPOSITION_DATA_SUCCESS',
    WALKOUT_DISPOSITION_DATA_ERR : 'WALKOUT_DISPOSITION_DATA_ERR',

    WALKOUT_REPORT_DATA_BEGIN : 'WALKOUT_REPORT_DATA_BEGIN',
    WALKOUT_REPORT_DATA_SUCCESS: 'WALKOUT_REPORT_DATA_SUCCESS',
    WALKOUT_REPORT_DATA_ERR : 'WALKOUT_REPORT_DATA_ERR',

    WALKOUT_REPORT_SAVE_DATA_BEGIN : 'WALKOUT_REPORT_SAVE_DATA_BEGIN',
    WALKOUT_REPORT_SAVE_DATA_SUCCESS: 'WALKOUT_REPORT_SAVE_DATA_SUCCESS',
    WALKOUT_REPORT_SAVE_DATA_ERR : 'WALKOUT_REPORT_SAVE_DATA_ERR',

    WALKOUT_REPORT_DOWNLOAD_DATA_BEGIN : 'WALKOUT_REPORT_DOWNLOAD_DATA_BEGIN',
    WALKOUT_REPORT_DOWNLOAD_DATA_SUCCESS: 'WALKOUT_REPORT_DOWNLOAD_DATA_SUCCESS',
    WALKOUT_REPORT_DOWNLOAD_DATA_ERR : 'WALKOUT_REPORT_DOWNLOAD_DATA_ERR',
    
    WALKOUT_ADD_NOTES_DATA_BEGIN : 'WALKOUT_ADD_NOTES_DATA_BEGIN',
    WALKOUT_ADD_NOTES_DATA_SUCCESS: 'WALKOUT_ADD_NOTES_DATA_SUCCESS',
    WALKOUT_ADD_NOTES_DATA_ERR : 'WALKOUT_ADD_NOTES_DATA_ERR',

    WALKOUT_LEAD_ASSIGN_DATA_BEGIN : 'WALKOUT_LEAD_ASSIGN_DATA_BEGIN',
    WALKOUT_LEAD_ASSIGN_DATA_SUCCESS: 'WALKOUT_LEAD_ASSIGN_DATA_SUCCESS',
    WALKOUT_LEAD_ASSIGN_DATA_ERR : 'WALKOUT_LEAD_ASSIGN_DATA_ERR',
    WALKOUT_LEAD_ASSIGN_DATA_RESET:'WALKOUT_LEAD_ASSIGN_DATA_RESET',

    WALKOUT_LEAD_ASSIGN_BULK_DATA_BEGIN : 'WALKOUT_LEAD_ASSIGN_BULK_DATA_BEGIN',
    WALKOUT_LEAD_ASSIGN_BULK_DATA_SUCCESS: 'WALKOUT_LEAD_ASSIGN_BULK_DATA_SUCCESS',
    WALKOUT_LEAD_ASSIGN_BULK_DATA_ERR : 'WALKOUT_LEAD_ASSIGN_BULK_DATA_ERR',
    WALKOUT_LEAD_ASSIGN_BULK_DATA_RESET : 'WALKOUT_LEAD_ASSIGN_BULK_DATA_RESET',

    ZERO_WALKOUT_REPORT_DATA_BEGIN: 'ZERO_WALKOUT_REPORT_DATA_BEGIN',
    ZERO_WALKOUT_REPORT_DATA_SUCCESS: 'ZERO_WALKOUT_REPORT_DATA_SUCCESS',
    ZERO_WALKOUT_REPORT_DATA_ERR: 'ZERO_WALKOUT_REPORT_DATA_ERR',

    walkoutDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    walkoutDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    walkoutDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.WALKOUT_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    walkoutDashboardRegionDataBegin: () => {
        return {
            type: actions.WALKOUT_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    walkoutDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.WALKOUT_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    walkoutDashboardRegionDataErr: (err) => {
        return {
            type: actions.WALKOUT_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    walkoutDashboardCategoryDataBegin: () => {
        return {
            type: actions.WALKOUT_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    walkoutDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.WALKOUT_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    walkoutDashboardCategoryDataErr: (err) => {
        return {
            type: actions.WALKOUT_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },


    walkoutDashboardTicketDataBegin: () => {
        return {
            type: actions.WALKOUT_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    walkoutDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.WALKOUT_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    walkoutDashboardTicketDataErr: (err) => {
        return {
            type: actions.WALKOUT_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    walkoutDashboardSummaryDataBegin: () => {
        return {
            type: actions.WALKOUT_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    walkoutDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    walkoutDashboardSummaryDataErr: (err) => {
        return {
            type: actions.WALKOUT_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },

    walkoutQueriesDataBegin: () => {
        return {
            type: actions.WALKOUT_QUERIES_DATA_BEGIN,
        };
    },

    walkoutQueriesDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_QUERIES_DATA_SUCCESS,
            data,
        };
    },

    walkoutQueriesDataErr: (err) => {
        return {
            type: actions.WALKOUT_QUERIES_DATA_ERR,
            err,
        };
    },

    walkoutQueriesStatusDataBegin: () => {
        return {
            type: actions.WALKOUT_QUERIES_STATUS_DATA_BEGIN,
        };
    },

    walkoutQueriesStatusDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_QUERIES_STATUS_DATA_SUCCESS,
            data,
        };
    },

    walkoutQueriesStatusDataErr: (err) => {
        return {
            type: actions.WALKOUT_QUERIES_STATUS_DATA_ERR,
            err,
        };
    },

    walkoutQueriesStatusDataReset: (err) => {
        return {
            type: actions.WALKOUT_QUERIES_STATUS_DATA_RESET,
            err,
        };
    },


    walkoutReasonDataBegin: () => {
        return {
            type: actions.WALKOUT_REASON_DATA_BEGIN,
        };
    },

    walkoutReasonDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_REASON_DATA_SUCCESS,
            data,
        };
    },

    walkoutReasonDataErr: (err) => {
        return {
            type: actions.WALKOUT_REASON_DATA_ERR,
            err,
        };
    },

    walkoutHistoryDataBegin: () => {
        return {
            type: actions.WALKOUT_HISTORY_DATA_BEGIN,
        };
    },

    walkoutHistoryDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    walkoutHistoryDataErr: (err) => {
        return {
            type: actions.WALKOUT_HISTORY_DATA_ERR,
            err,
        };
    },


    walkoutDispositionDataBegin: () => {
        return {
            type: actions.WALKOUT_DISPOSITION_DATA_BEGIN,
        };
    },

    walkoutDispositionDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_DISPOSITION_DATA_SUCCESS,
            data,
        };
    },

    walkoutDispositionDataErr: (err) => {
        return {
            type: actions.WALKOUT_DISPOSITION_DATA_ERR,
            err,
        };
    },

    walkoutReportDataBegin: () => {
        return {
            type: actions.WALKOUT_REPORT_DATA_BEGIN,
        };
    },

    walkoutReportDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_REPORT_DATA_SUCCESS,
            data,
        };
    },

    walkoutReportDataErr: (err) => {
        return {
            type: actions.WALKOUT_REPORT_DATA_ERR,
            err,
        };
    },

    walkoutReportSaveDataBegin: () => {
        return {
            type: actions.WALKOUT_REPORT_SAVE_DATA_BEGIN,
        };
    },

    walkoutReportSaveDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    walkoutReportSaveDataErr: (err) => {
        return {
            type: actions.WALKOUT_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    walkoutReportDownloadDataBegin: () => {
        return {
            type: actions.WALKOUT_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    walkoutReportDownloadDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    walkoutReportDownloadDataErr: (err) => {
        return {
            type: actions.WALKOUT_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

    walkoutSubmitNotesDataBegin: () => {
        return {
            type: actions.WALKOUT_ADD_NOTES_DATA_BEGIN,
        };
    },

    walkoutSubmitNotesDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_ADD_NOTES_DATA_SUCCESS,
            data,
        };
    },

    walkoutSubmitNotesDataErr: (err) => {
        return {
            type: actions.WALKOUT_ADD_NOTES_DATA_ERR,
            err,
        };
    },

    walkoutLeadAssignDataBegin: () => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_DATA_BEGIN,
        };
    },

    walkoutLeadAssignDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_DATA_SUCCESS,
            data,
        };
    },

    walkoutLeadAssignDataErr: (err) => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_DATA_ERR,
            err,
        };
    },

    walkoutLeadAssignDataReset: (err) => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_DATA_RESET,
            err,
        };
    },

    walkoutLeadAssignBulkDataBegin: () => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_BULK_DATA_BEGIN,
        };
    },

    walkoutLeadAssignBulkDataSuccess: (data) => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_BULK_DATA_SUCCESS,
            data,
        };
    },

    walkoutLeadAssignBulkDataErr: (err) => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_BULK_DATA_ERR,
            err,
        };
    },

    walkoutLeadAssignBulkDataReset: (err) => {
        return {
            type: actions.WALKOUT_LEAD_ASSIGN_BULK_DATA_RESET,
            err,
        };
    },

    
    
    zeroWalkoutReportDataBegin: () => {
        return {
            type: actions.ZERO_WALKOUT_REPORT_DATA_BEGIN,
        };
    },

    zeroWalkoutReportDataSuccess: (data) => {
        return {
            type: actions.ZERO_WALKOUT_REPORT_DATA_SUCCESS,
            data,
        };
    },

    zeroWalkoutReportDataErr: (err) => {
        return {
            type: actions.ZERO_WALKOUT_REPORT_DATA_ERR,
            err,
        };
    },
   
};

export default actions;
