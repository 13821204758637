import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, Drawer, Divider, Checkbox } from 'antd';
import Heading from '../heading/heading';
import { GeneralFormWrapper } from '../../container/styled';
import { updateEmployeeUserType } from '../../redux/employee/actionCreator';
import { CustomerDetailsWrapper} from '../../container/agent/style';
import { useDispatch, useSelector } from 'react-redux'; 
import { getMaintenanceDepartment } from '../../redux/maintenance/actionCreator';
import { adminPortalIcon, agentPortalIcon, employeePortalIcon, maintenancePortalIcon, stockPortalIcon, purchasePortalIcon } from '../../utility/portalIcon';
import { getBranchList, getNewCategoryList, getStateList } from '../../redux/common/actionCreator';

const ChangePermission = ({showPermission,setShowPermission,setPassData,...props}) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const employeeDetails = props.employeeData;

    const [list,setList]                                            = useState([]);
    const [showPermissionControl,setShowPermissionControl]          = useState(false);
    const [checkedList, setCheckedList]                             = useState([]);
    const [indeterminate, setIndeterminate]                         = useState(false);
    const [checkAll, setCheckAll]                                   = useState(false);
    const [showMaintenanceDepartment,setShowMaintenanceDepartment]  = useState(false);
    const [showStateList, setShowStateList]                         = useState(false);

    const [showCategoryList,setShowCategoryList]                    = useState(false);
    const [showBranchList,setShowBranchList]                        = useState(false);

    const onClose = () => {
        setShowPermission(false);
        setPassData({
            employeeData : ''
        })
        form.resetFields();
        setShowPermissionControl(false);
        resetPermissionList();
    }
    
    useEffect(() => {
        if(showPermission){
            form.resetFields();
            setShowPermissionControl(false);
            resetPermissionList();
        }   
        //
    },[form,showPermission])

    useEffect(() => {
        dispatch(getMaintenanceDepartment());
        dispatch(getNewCategoryList());
        dispatch(getBranchList());
        dispatch(getStateList());
    },[dispatch])

    const maintenanceDepartmentData = useSelector((state) => state.maintenanceDepartment.data);
    const stateListData             = useSelector((state) => state.stateList.data);
    const categoryListData          = useSelector((state) => state.category.data);
    const brancheListData           = useSelector((state) => state.branch.data);

    const checkPermissionList = (value) => {
        if(value === 'admin'){
            setList(adminPortalIcon);
            setShowStateList(true);
        }
        
        if(value === 'agent'){
            setList(agentPortalIcon);
        }
            
        if(value === 'employee'){
            setList(employeePortalIcon);
        }
        
        if(value === 'maintenance'){
            setList(maintenancePortalIcon);
            setShowMaintenanceDepartment(true);
            setShowStateList(true);
        }
        
        if(value === 'stock'){
            setList(stockPortalIcon);
        }

        if(value === 'purchase-enquiry'){
            setList(purchasePortalIcon);
            setShowCategoryList(true);
            setShowBranchList(true);
        }


    }

    const resetPermissionList = () => {
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
        setShowCategoryList(false);
        setShowBranchList(false);
    }

    const onHandleUserType = (value) => {
        resetPermissionList();
        setShowMaintenanceDepartment(false);
        setShowStateList(false);
        setShowPermissionControl(true);
        checkPermissionList(value);
        form.setFieldsValue({
            user_type: value,
        });
        
    }

    const autoHandleUserType = (value) =>{
        setShowMaintenanceDepartment(false);
        setShowStateList(false);
        setShowPermissionControl(true);
        checkPermissionList(value);
    }

    useEffect(() => {
        if(employeeDetails?.user_type && employeeDetails.user_type !== ''){
            autoHandleUserType(employeeDetails?.user_type)
            setCheckedList(employeeDetails?.permission);
            // setIndeterminate(employeeDetails?.permission && list && employeeDetails.permission.length < list.length ? true : false);
            setCheckAll(employeeDetails?.permission.length === list.length);
        }   
        //eslint-disable-next-line
    },[employeeDetails])


    useEffect(() => {
        console.log('employeeDetails.permission.length',employeeDetails.permission.length)
        console.log('list.length',list.length)
        if(employeeDetails?.user_type && employeeDetails.user_type !== ''&& list  && list.length > 0) {
            console.log('employeeDetails.permission.length',employeeDetails.permission.length)
            console.log('list.length',list.length)
            setIndeterminate(employeeDetails?.permission && list && employeeDetails.permission.length < list.length ? true : false);
        }
    },[employeeDetails,list])
   
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ?  list.map(li => li.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < list.length);
        setCheckAll(checkedValues.length === list.length);
    };

    const handleSubmit = (value) => {
        const modifiedValue = { ...value,permission:checkedList, employee_id:employeeDetails.id};
        dispatch(updateEmployeeUserType(modifiedValue));
        form.resetFields();
        setShowPermission(false);
    }
    
    useEffect(() => {
        if(employeeDetails){
            const initialValues = {
                user_type:employeeDetails?.user_type,
                department_id: employeeDetails?.department?.map(dept => dept.id),
                allow_state:employeeDetails?.allowed_state,
                sap_group_category_list: employeeDetails?.sap_group_category,
                purchase_enquiry_branch_list: employeeDetails?.purchase_enquiry_branch,

            }
            form.setFieldsValue(initialValues);
        }   
    },[employeeDetails,form])

    const loginTypeData = [
        {name:"Admin",id:"admin"},
        {name:"Agent",id:"agent"},
        {name:"Store Employee",id:"employee"},
        {name:"Maintenance Employee",id:"maintenance"},
        {name:"Stock Employee",id:"stock"},
        {name:"Purchase Enquiry",id:"purchase-enquiry"}
    ];

    return (
        <>
            <Drawer title="Role Permission" height={'90%'} onClose={onClose} open={showPermission} bodyStyle={{ paddingBottom: 80, }} placement='top'>
                <GeneralFormWrapper>
                    <CustomerDetailsWrapper>
                        <Row gutter={25}>
                            <Col xxl={24} xl={24} xs={24}>
                                <div className="about-project">
                                    <Row gutter={25}>
                                        {employeeDetails?.employee_id && (
                                        <Col xxl={4} xl={6}  lg={6} xs={24}>
                                            <span> Employee ID </span>
                                            <p>{employeeDetails?.employee_id ? employeeDetails.employee_id : '-' }</p>
                                        </Col>
                                        )}
                                        {employeeDetails?.name && (
                                        <Col xxl={4} xl={6}  lg={6} xs={24}>
                                            <span> Employee Name</span>
                                            <p>{employeeDetails?.name ? employeeDetails.name : '-' }</p>
                                        </Col>
                                        )}
                                        {employeeDetails?.mobile && (
                                        <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                            <span> Mobile Number </span>
                                            <p className="color-primary">{employeeDetails?.mobile ? employeeDetails.mobile : '-' }</p>
                                        </Col>
                                        )}
                                        {employeeDetails?.email && (
                                        <Col xxl={4}  xl={6}  lg={6}  xs={24}>
                                            <span>Email</span>
                                            <p>{employeeDetails?.email  ? employeeDetails.email : '-' }</p>
                                        </Col>
                                        )}

                                        {employeeDetails?.branch?.name && (
                                        <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                            <span>Branch</span>
                                            <p >{employeeDetails?.branch?.name ? employeeDetails.branch?.name : '-' }</p>
                                        </Col>
                                        )}
                                        
                                        {employeeDetails?.employee_category?.name && (
                                            <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                                <span>Category</span>
                                                <p>{employeeDetails?.employee_category?.name ? employeeDetails.employee_category.name : '-' }</p>
                                            </Col>
                                        )}
                                        
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CustomerDetailsWrapper>
                    <Divider dashed />
                    <Heading as="h4"> Permission </Heading>
                    <Divider dashed />
                    <Form  form={form} onFinish={handleSubmit}>
                        <Row gutter={24}>
                            <Col span={7} xs={24} lg={6} >
                                <Form.Item name="user_type" label="Login Type" rules={[ { required: true, message: 'Please Choose any one Login Type' }]} >
                                    <Select style={{ width: '100%' }} 
                                        placeholder="Please choose the Login Type" 
                                        options={loginTypeData && loginTypeData.map(data => ({ label: data.name, value: data.id }))}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        showSearch
                                        showArrow={true}
                                        notFoundContent={null}
                                        onChange={onHandleUserType}
                                        allowClear
                                        >
                                    </Select>
                                </Form.Item>
                            </Col>
                            { showStateList && (
                            <Col span={7} xs={24} lg={6} >
                                <Form.Item name="allow_state" label="Allow State" rules={[ { required: true, message: 'Please Choose any one Allow State' }]} >
                                    <Select style={{ width: '100%' }} 
                                        mode="multiple"
                                        placeholder="Please choose the Allow State" 
                                        options={stateListData && stateListData.map(data => ({ label: data.name, value: data.id }))}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        showSearch
                                        showArrow={true}
                                        notFoundContent={null}
                                        allowClear
                                        >
                                    </Select>
                                </Form.Item>
                            </Col>
                            )}
                            { showMaintenanceDepartment && (
                            <Col span={5} xs={24} lg={6} >
                                <Form.Item name="department_id" label="Department" rules={[ { required: true, message: 'Please Choose any one Department' }]} >
                                <Select 
                                    mode="multiple"
                                    placeholder="Please choose the Department" 
                                    options={maintenanceDepartmentData && maintenanceDepartmentData.map(data => ({ label: data.name, value: data.id }))}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    showSearch
                                    showArrow={true}
                                    notFoundContent={null}
                                    allowClear
                                >
                                </Select>
                                </Form.Item>
                            </Col>
                            )}


                            { showCategoryList && (
                            <Col span={5} xs={24} lg={6} >
                                <Form.Item name="sap_group_category_list" label="Category" rules={[ { required: true, message: 'Please Choose any one Category' }]} >
                                <Select 
                                    mode="multiple"
                                    placeholder="Please choose the Category" 
                                    options={categoryListData && categoryListData.map(data => ({ label: data.title, value: data.id }))}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    showSearch
                                    showArrow={true}
                                    notFoundContent={null}
                                    allowClear
                                >
                                </Select>
                                </Form.Item>
                            </Col>
                            )}

                            { showBranchList && (
                            <Col span={5} xs={24} lg={6} >
                                <Form.Item name="purchase_enquiry_branch_list" label="Branch" rules={[ { required: true, message: 'Please Choose any one Branch' }]} >
                                <Select 
                                    mode="multiple"
                                    placeholder="Please choose the Branch" 
                                    options={brancheListData && brancheListData.map(data => ({ label: data.name, value: data.id }))}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    showSearch
                                    showArrow={true}
                                    notFoundContent={null}
                                    allowClear
                                >
                                </Select>
                                </Form.Item>
                            </Col>
                            )}

                        </Row>
                        { showPermissionControl && list && list.length>0 && (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Heading as="h4"> 
                                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} >
                                                Check All Portal
                                            </Checkbox>
                                        </Heading>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Checkbox.Group style={{ width: '100%' }} value={checkedList} onChange={onChange}>
                                            <Row>
                                                {list && list.map((lis,key) => (
                                                    <Col xxl={3} xl={3} lg={3} xs={12} key={'column'+key}>
                                                        <Checkbox key={'check-'+key} value={lis.value}>
                                                            <div className="text-center">
                                                                <div className="text-center">
                                                                    <img src={lis.image_url} width='100' alt='' />
                                                                </div>
                                                                {lis.title}
                                                            </div>
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <div className="sDash_form-action">
                            <button type="submit" className="ant-btn ant-btn-primary ant-btn-lg mt-30">
                                Submit
                            </button>
                        </div>
                    </Form>
                </GeneralFormWrapper>
            </Drawer>
        </>
    )
}

export default ChangePermission