import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../container/service_matrix/Dashboard'));
const List              = lazy(() => import('../../container/service_matrix/ListServiceMatrix'));
const Add               = lazy(() => import('../../container/service_matrix/AddServiceMatrix'));
const Edit              = lazy(() => import('../../container/service_matrix/EditServiceMatrix'));
const View              = lazy(() => import('../../container/service_matrix/ViewDetails'));

const UploadDump        = lazy(() => import('../../container/service_matrix/UploadDump'))
const UploadDumpStatus  = lazy(() => import('../../container/service_matrix/UploadReport'))

const Report            = lazy(() => import('../../container/service_matrix/Report'));
const DownloadReport    = lazy(() => import('../../container/service_matrix/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function BrandEscalationRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            {/* <Route exact path={`${path}/service_type`} component={List} />
            <Route exact path={`${path}/service_type_add`} component={List} /> */}
            <Route exact path={`${path}/list`} component={List} />
            <Route exact path={`${path}/list/add`} component={Add} />
            <Route exact path={`${path}/list/edit`} component={Edit} />
            <Route exact path={`${path}/list/view`} component={View} />
            <Route exact path={`${path}/upload_dump`} component={UploadDump} />
            <Route exact path={`${path}/upload_dump_status`} component={UploadDumpStatus} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(BrandEscalationRoutes);