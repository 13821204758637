import React, { Component } from "react";
import { Layout, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import propTypes from "prop-types";
import MenueItems from "./MenueItems";
import TopMenu from "./TopMenu";
import { Div } from "./style";
import AuthInfo from "../components/utilities/auth-info/info";
import {
  changeRtlMode,
  changeLayoutMode,
  changeMenuMode,
} from "../redux/themeLayout/actionCreator";
import moment from "moment";

const { darkTheme } = require("../config/theme/themeVariables");
const { Header, Footer, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
    class LayoutComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                collapsed: window.innerWidth <= 1200,
            };
        }

        componentDidMount() {
            window.addEventListener("resize", this.updateDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateDimensions);
        }

        updateDimensions = () => {
            this.setState({ collapsed: window.innerWidth <= 1200 });
        };

        toggleCollapsed = () => {
            this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
        };

        toggleCollapsedMobile = () => {
            if (window.innerWidth <= 768) {
                this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
            }
        };

        onRtlChange = () => {
            document.documentElement.setAttribute("dir", "rtl");
            this.props.changeRtl(true);
        };

        onLtrChange = () => {
            document.documentElement.setAttribute("dir", "ltr");
            this.props.changeRtl(false);
        };

        modeChangeDark = () => this.props.changeLayout(true);
        modeChangeLight = () => this.props.changeLayout(false);
        modeChangeTopNav = () => this.props.changeMenuMode(true);
        modeChangeSideNav = () => this.props.changeMenuMode(false);

        render() {
            const { collapsed } = this.state;
            const { ChangeLayoutMode, rtl, topMenu } = this.props;
            const left = !rtl ? "left" : "right";
            const darkMode = ChangeLayoutMode;

        return (
            <Div darkMode={darkMode}>
                <Layout className="layout">
                    <Header>
                        <Row>
                            <Col lg={1} md={6} sm={6} xs={12}>
                            <div className="align-center-v">
                                {!topMenu || window.outerWidth <= 990 ? (
                                <Button type="link" onClick={this.toggleCollapsed}>
                                    <img
                                    src={require(`../static/img/icon/${collapsed ? "right.svg" : "left.svg"}`)}
                                    alt="menu"
                                    />
                                </Button>
                                ) : null}
                                <Link
                                className={topMenu && window.outerWidth > 1025 ? "logo top-menu" : "logo"}
                                to="/admin"
                                >
                                <img
                                    src={require(`../static/img/logo.png`)}
                                    alt="PAI"
                                />
                                </Link>
                            </div>
                            </Col>
                            <Col lg={21} md={0} sm={0} xs={0}>
                            {topMenu && window.outerWidth >= 1024 ? <TopMenu /> : null}
                            </Col>
                            <Col lg={2} md={18} sm={0} xs={12}>
                            <AuthInfo />
                            </Col>
                        </Row>
                    </Header>
                    <Layout>
                        {!topMenu || window.outerWidth <= 1024 ? (
                            <ThemeProvider theme={darkTheme}>
                                <Sider
                                    width={280}
                                    style={{
                                    margin: 0,
                                    padding: "15px 15px 55px",
                                    overflowY: "auto",
                                    height: "100vh",
                                    position: "fixed",
                                    [left]: 0,
                                    zIndex: 998,
                                    }}
                                    collapsed={collapsed}
                                    theme={!darkMode ? "light" : "dark"}
                                >
                                    <Scrollbars autoHide>
                                        <MenueItems
                                            topMenu={topMenu}
                                            rtl={rtl}
                                            toggleCollapsed={this.toggleCollapsedMobile}
                                            darkMode={darkMode}
                                            events={{
                                            onRtlChange: this.onRtlChange,
                                            onLtrChange: this.onLtrChange,
                                            modeChangeDark: this.modeChangeDark,
                                            modeChangeLight: this.modeChangeLight,
                                            modeChangeTopNav: this.modeChangeTopNav,
                                            modeChangeSideNav: this.modeChangeSideNav,
                                            }}
                                        />
                                    </Scrollbars>
                                </Sider>
                            </ThemeProvider>
                        ) : null}
                        <Layout className="atbd-main-layout">
                            <Content>
                            <WrappedComponent {...this.props} />
                            <Footer className="admin-footer" style={{ padding: "20px 30px" }}>
                                <span>
                                    2023 - {moment().format("YYYY")} © SkilledAnswers Info Solutions Pvt Ltd
                                </span>
                            </Footer>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </Div>
        );
        }
    }

    LayoutComponent.propTypes = {
        ChangeLayoutMode: propTypes.bool,
        rtl: propTypes.bool,
        topMenu: propTypes.bool,
        changeRtl: propTypes.func,
        changeLayout: propTypes.func,
        changeMenuMode: propTypes.func,
    };

    const mapStateToProps = (state) => ({
        ChangeLayoutMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
    });

    const mapDispatchToProps = (dispatch) => ({
        changeRtl: (rtl) => dispatch(changeRtlMode(rtl)),
        changeLayout: (show) => dispatch(changeLayoutMode(show)),
        changeMenuMode: (show) => dispatch(changeMenuMode(show)),
    });

    return connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
};

export default ThemeLayout;
