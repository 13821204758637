import actions from './actions';

const { 
    CHANNEL_BEGIN, CHANNEL_SUCCESS, CHANNEL_ERR, 
    LIVE_CALL_LIST_BEGIN, LIVE_CALL_LIST_SUCCESS, LIVE_CALL_LIST_ERR, 
    CALL_MONITER_REPORT_BEGIN, CALL_MONITER_REPORT_SUCCESS, CALL_MONITER_REPORT_ERR, 
    CALL_MARGE_REPORT_BEGIN, CALL_MARGE_REPORT_SUCCESS, CALL_MARGE_REPORT_ERR, 
    INCOMING_VOIP_REPORT_BEGIN, INCOMING_VOIP_REPORT_SUCCESS, INCOMING_VOIP_REPORT_ERR, 
    SIGNIN_SIGNOUT_REPORT_BEGIN, SIGNIN_SIGNOUT_REPORT_SUCCESS, SIGNIN_SIGNOUT_REPORT_ERR,   
    CALL_ACTION_DATA_BEGIN, CALL_ACTION_DATA_SUCCESS, CALL_ACTION_DATA_ERR, 
    HOURS_REPORT_BEGIN, HOURS_REPORT_SUCCESS, HOURS_REPORT_ERR, 
    REQUEST_TYPE_BEGIN, REQUEST_TYPE_SUCCESS, REQUEST_TYPE_ERR, 
    REQUEST_TYPE_SUBMIT_BEGIN, REQUEST_TYPE_SUBMIT_SUCCESS, REQUEST_TYPE_SUBMIT_ERR, 
    SUB_REQUEST_TYPE_BEGIN, SUB_REQUEST_TYPE_SUCCESS, SUB_REQUEST_TYPE_ERR, 
    SUB_REQUEST_TYPE_SUBMIT_BEGIN,SUB_REQUEST_TYPE_SUBMIT_SUCCESS,SUB_REQUEST_TYPE_SUBMIT_ERR,
    AGENT_FORCE_LOGOUT_BEGIN,AGENT_FORCE_LOGOUT_SUCCESS,AGENT_FORCE_LOGOUT_ERR,AGENT_FORCE_LOGOUT_RESET,
    LIVE_COUNT_SELF_BEGIN, LIVE_COUNT_SELF_SUCCESS, LIVE_COUNT_SELF_ERR,
    LIVE_COUNT_BLR_BEGIN, LIVE_COUNT_BLR_SUCCESS, LIVE_COUNT_BLR_ERR,
    CUSTOMER_CARE_REPORT_DATA_BEGIN, CUSTOMER_CARE_REPORT_DATA_SUCCESS, CUSTOMER_CARE_REPORT_DATA_ERR, CUSTOMER_CARE_REPORT_DATA_RESET,
    CUSTOMER_CARE_REPORT_SAVE_DATA_BEGIN, CUSTOMER_CARE_REPORT_SAVE_DATA_SUCCESS, CUSTOMER_CARE_REPORT_SAVE_DATA_ERR, 
    CUSTOMER_CARE_REPORT_DOWNLOAD_DATA_BEGIN, CUSTOMER_CARE_REPORT_DOWNLOAD_DATA_SUCCESS, CUSTOMER_CARE_REPORT_DOWNLOAD_DATA_ERR, 

    UPDATE_EMPLOYEE_LANGUAGE_DATA_BEGIN, UPDATE_EMPLOYEE_LANGUAGE_DATA_SUCCESS, UPDATE_EMPLOYEE_LANGUAGE_DATA_ERR, UPDATE_EMPLOYEE_LANGUAGE_DATA_RESET,
} = actions;


const initialStateFilter = { data: '', loading: false, error: null, };

const initialState = { data: [], loading: false, error: null, };


const channelReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CHANNEL_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CHANNEL_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CHANNEL_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const liveCallListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case LIVE_CALL_LIST_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case LIVE_CALL_LIST_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case LIVE_CALL_LIST_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const callMoniterReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CALL_MONITER_REPORT_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CALL_MONITER_REPORT_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CALL_MONITER_REPORT_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const callMargeReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CALL_MARGE_REPORT_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CALL_MARGE_REPORT_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CALL_MARGE_REPORT_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const incomingVOIPReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INCOMING_VOIP_REPORT_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case INCOMING_VOIP_REPORT_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case INCOMING_VOIP_REPORT_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const signInSignOutReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SIGNIN_SIGNOUT_REPORT_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SIGNIN_SIGNOUT_REPORT_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SIGNIN_SIGNOUT_REPORT_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const hoursReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case HOURS_REPORT_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case HOURS_REPORT_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case HOURS_REPORT_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const callActionReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CALL_ACTION_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case CALL_ACTION_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case CALL_ACTION_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const requestTypeReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REQUEST_TYPE_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case REQUEST_TYPE_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case REQUEST_TYPE_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const requestTypeSubmitReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REQUEST_TYPE_SUBMIT_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case REQUEST_TYPE_SUBMIT_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case REQUEST_TYPE_SUBMIT_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const subRequestTypeReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SUB_REQUEST_TYPE_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case SUB_REQUEST_TYPE_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case SUB_REQUEST_TYPE_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const subRequestTypeSubmitReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SUB_REQUEST_TYPE_SUBMIT_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case SUB_REQUEST_TYPE_SUBMIT_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case SUB_REQUEST_TYPE_SUBMIT_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const agentForceLogoutReducers = (state = initialStateFilter, action) =>{
    const { type, data, err } = action;
    switch (type) {
        case AGENT_FORCE_LOGOUT_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case AGENT_FORCE_LOGOUT_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case AGENT_FORCE_LOGOUT_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        case AGENT_FORCE_LOGOUT_RESET:
            return initialStateFilter
        default:
            return state;
    }
}

const liveCountSelfReducers = (state = initialStateFilter, action) =>{
    const { type, data, err } = action;
    switch (type) {
        case LIVE_COUNT_SELF_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case LIVE_COUNT_SELF_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case LIVE_COUNT_SELF_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
}

const liveCountBlrReducers = (state = initialStateFilter, action) =>{
    const { type, data, err } = action;
    switch (type) {
        case LIVE_COUNT_BLR_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case LIVE_COUNT_BLR_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case LIVE_COUNT_BLR_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
}

const customercareReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_CARE_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CUSTOMER_CARE_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CUSTOMER_CARE_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case CUSTOMER_CARE_REPORT_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};




const customercareReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_CARE_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CUSTOMER_CARE_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CUSTOMER_CARE_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const customercareReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case CUSTOMER_CARE_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case CUSTOMER_CARE_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case CUSTOMER_CARE_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};



const updateEmployeeLanguageReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_EMPLOYEE_LANGUAGE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_EMPLOYEE_LANGUAGE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_EMPLOYEE_LANGUAGE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case UPDATE_EMPLOYEE_LANGUAGE_DATA_RESET:
            return initialState;

        default:
            return state;
    }
}



export { 
    channelReducers, liveCallListReducers, callMoniterReportReducers, callMargeReportReducers, incomingVOIPReportReducers, 
    signInSignOutReportReducers, callActionReducers, hoursReportReducers, 
    requestTypeReducers,requestTypeSubmitReducers, subRequestTypeReducers, subRequestTypeSubmitReducers, 
    agentForceLogoutReducers,
    liveCountSelfReducers, liveCountBlrReducers,
    customercareReportReducers, customercareReportSaveReducers, customercareReportDownloadReducers, updateEmployeeLanguageReducers,
};
