const actions = {

    COMPLAINT_LIST_BEGIN : 'COMPLAINT_LIST_BEGIN',
    COMPLAINT_LIST_SUCCESS : 'COMPLAINT_LIST_SUCCESS',
    COMPLAINT_LIST_ERR : 'COMPLAINT_LIST_ERR',

    HAPPY_COMPLAINT_LIST_BEGIN : 'HAPPY_COMPLAINT_LIST_BEGIN',
    HAPPY_COMPLAINT_LIST_SUCCESS : 'HAPPY_COMPLAINT_LIST_SUCCESS',
    HAPPY_COMPLAINT_LIST_ERR : 'HAPPY_COMPLAINT_LIST_ERR',

    GET_PRIORITY_LIST_BEGIN:'GET_PRIORITY_LIST_BEGIN', 
    GET_PRIORITY_LIST_SUCCESS:    'GET_PRIORITY_LIST_SUCCESS',
    GET_PRIORITY_LIST_ERR:    'GET_PRIORITY_LIST_ERR',

    GET_SCHEDULE_LIST_BEGIN:    'GET_SCHEDULE_LIST_BEGIN',
    GET_SCHEDULE_LIST_SUCCESS:    'GET_SCHEDULE_LIST_SUCCESS',
    GET_SCHEDULE_LIST_ERR:    'GET_SCHEDULE_LIST_ERR', 

    
    ASSIGN_AGENT_COMPLAINT_DATA_BEGIN : 'ASSIGN_AGENT_COMPLAINT_DATA_BEGIN',
    ASSIGN_AGENT_COMPLAINT_DATA_SUCCESS: 'ASSIGN_AGENT_COMPLAINT_DATA_SUCCESS',
    ASSIGN_AGENT_COMPLAINT_DATA_ERR : 'ASSIGN_AGENT_COMPLAINT_DATA_ERR',
    ASSIGN_AGENT_COMPLAINT_DATA_RESET : 'ASSIGN_AGENT_COMPLAINT_DATA_RESET',

    COMPLAINT_REGISTER_HISTORY_DATA_BEGIN : 'COMPLAINT_REGISTER_HISTORY_DATA_BEGIN',
    COMPLAINT_REGISTER_HISTORY_DATA_SUCCESS : 'COMPLAINT_REGISTER_HISTORY_DATA_SUCCESS',
    COMPLAINT_REGISTER_HISTORY_DATA_ERR : 'COMPLAINT_REGISTER_HISTORY_DATA_ERR',

    UNASSIGN_AGENT_COMPLAINT_DATA_BEGIN : 'UNASSIGN_AGENT_COMPLAINT_DATA_BEGIN',
    UNASSIGN_AGENT_COMPLAINT_DATA_SUCCESS: 'UNASSIGN_AGENT_COMPLAINT_DATA_SUCCESS',
    UNASSIGN_AGENT_COMPLAINT_DATA_ERR : 'UNASSIGN_AGENT_COMPLAINT_DATA_ERR',
    UNASSIGN_AGENT_COMPLAINT_DATA_RESET : 'UNASSIGN_AGENT_COMPLAINT_DATA_RESET',

    ABANDONED_LIST_DATA_BEGIN : 'ABANDONED_LIST_DATA_BEGIN',
    ABANDONED_LIST_DATA_SUCCESS: 'ABANDONED_LIST_DATA_SUCCESS',
    ABANDONED_LIST_DATA_ERR : 'ABANDONED_LIST_DATA_ERR',

    ASSIGN_AGENT_ABANDONED_DATA_BEGIN : 'ASSIGN_AGENT_ABANDONED_DATA_BEGIN',
    ASSIGN_AGENT_ABANDONED_DATA_SUCCESS: 'ASSIGN_AGENT_ABANDONED_DATA_SUCCESS',
    ASSIGN_AGENT_ABANDONED_DATA_ERR : 'ASSIGN_AGENT_ABANDONED_DATA_ERR',
    ASSIGN_AGENT_ABANDONED_DATA_RESET : 'ASSIGN_AGENT_ABANDONED_DATA_RESET',

    VIEW_ABANDONED_DATA_BEGIN : 'VIEW_ABANDONED_DATA_BEGIN',
    VIEW_ABANDONED_DATA_SUCCESS: 'VIEW_ABANDONED_DATA_SUCCESS',
    VIEW_ABANDONED_DATA_ERR : 'VIEW_ABANDONED_DATA_ERR',

    ABANDONED_HISTORY_DATA_BEGIN : 'ABANDONED_HISTORY_DATA_BEGIN',
    ABANDONED_HISTORY_DATA_SUCCESS: 'ABANDONED_HISTORY_DATA_SUCCESS',
    ABANDONED_HISTORY_DATA_ERR : 'ABANDONED_HISTORY_DATA_ERR',


    MANDATORY_LIST_DATA_BEGIN : 'MANDATORY_LIST_DATA_BEGIN',
    MANDATORY_LIST_DATA_SUCCESS: 'MANDATORY_LIST_DATA_SUCCESS',
    MANDATORY_LIST_DATA_ERR : 'MANDATORY_LIST_DATA_ERR',

    ASSIGN_MANDATORY_DATA_BEGIN : 'ASSIGN_MANDATORY_DATA_BEGIN',
    ASSIGN_MANDATORY_DATA_SUCCESS: 'ASSIGN_MANDATORY_DATA_SUCCESS',
    ASSIGN_MANDATORY_DATA_ERR : 'ASSIGN_MANDATORY_DATA_ERR',
    ASSIGN_MANDATORY_DATA_RESET : 'ASSIGN_MANDATORY_DATA_RESET',

    VIEW_MANDATORY_DATA_BEGIN : 'VIEW_MANDATORY_DATA_BEGIN',
    VIEW_MANDATORY_DATA_SUCCESS: 'VIEW_MANDATORY_DATA_SUCCESS',
    VIEW_MANDATORY_DATA_ERR : 'VIEW_MANDATORY_DATA_ERR',

    MANDATORY_HISTORY_DATA_BEGIN : 'MANDATORY_HISTORY_DATA_BEGIN',
    MANDATORY_HISTORY_DATA_SUCCESS: 'MANDATORY_HISTORY_DATA_SUCCESS',
    MANDATORY_HISTORY_DATA_ERR : 'MANDATORY_HISTORY_DATA_ERR',


    ACCESSORIES_TELE_SALES_LIST_DATA_BEGIN : 'ACCESSORIES_TELE_SALES_LIST_DATA_BEGIN',
    ACCESSORIES_TELE_SALES_LIST_DATA_SUCCESS: 'ACCESSORIES_TELE_SALES_LIST_DATA_SUCCESS',
    ACCESSORIES_TELE_SALES_LIST_DATA_ERR : 'ACCESSORIES_TELE_SALES_LIST_DATA_ERR',

    ASSIGN_ACCESSORIES_TELE_SALES_DATA_BEGIN : 'ASSIGN_ACCESSORIES_TELE_SALES_DATA_BEGIN',
    ASSIGN_ACCESSORIES_TELE_SALES_DATA_SUCCESS: 'ASSIGN_ACCESSORIES_TELE_SALES_DATA_SUCCESS',
    ASSIGN_ACCESSORIES_TELE_SALES_DATA_ERR : 'ASSIGN_ACCESSORIES_TELE_SALES_DATA_ERR',
    ASSIGN_ACCESSORIES_TELE_SALES_DATA_RESET : 'ASSIGN_ACCESSORIES_TELE_SALES_DATA_RESET',

    VIEW_ACCESSORIES_TELE_SALES_DATA_BEGIN : 'VIEW_ACCESSORIES_TELE_SALES_DATA_BEGIN',
    VIEW_ACCESSORIES_TELE_SALES_DATA_SUCCESS: 'VIEW_ACCESSORIES_TELE_SALES_DATA_SUCCESS',
    VIEW_ACCESSORIES_TELE_SALES_DATA_ERR : 'VIEW_ACCESSORIES_TELE_SALES_DATA_ERR',

    ACCESSORIES_TELE_SALES_HISTORY_DATA_BEGIN : 'ACCESSORIES_TELE_SALES_HISTORY_DATA_BEGIN',
    ACCESSORIES_TELE_SALES_HISTORY_DATA_SUCCESS: 'ACCESSORIES_TELE_SALES_HISTORY_DATA_SUCCESS',
    ACCESSORIES_TELE_SALES_HISTORY_DATA_ERR : 'ACCESSORIES_TELE_SALES_HISTORY_DATA_ERR',

    DEMO_HAPPY_LIST_DATA_BEGIN : 'DEMO_HAPPY_LIST_DATA_BEGIN',
    DEMO_HAPPY_LIST_DATA_SUCCESS : 'DEMO_HAPPY_LIST_DATA_SUCCESS',
    DEMO_HAPPY_LIST_DATA_ERR : 'DEMO_HAPPY_LIST_DATA_SUCCESS',

    ASSIGN_DEMO_HAPPY_DATA_BEGIN : 'ASSIGN_DEMO_HAPPY_DATA_BEGIN',
    ASSIGN_DEMO_HAPPY_DATA_SUCCESS : 'ASSIGN_DEMO_HAPPY_DATA_SUCCESS',
    ASSIGN_DEMO_HAPPY_DATA_ERR : 'ASSIGN_DEMO_HAPPY_DATA_ERR',
    ASSIGN_DEMO_HAPPY_DATA_RESET : 'ASSIGN_DEMO_HAPPY_DATA_RESET',

    VIEW_DEMO_HAPPY_DETAILS_DATA_BEGIN : 'VIEW_DEMO_HAPPY_DETAILS_DATA_BEGIN',
    VIEW_DEMO_HAPPY_DETAILS_DATA_SUCCESS : 'VIEW_DEMO_HAPPY_DETAILS_DATA_SUCCESS',
    VIEW_DEMO_HAPPY_DETAILS_DATA_ERR : 'VIEW_DEMO_HAPPY_DETAILS_DATA_ERR',

    DEMO_HAPPY_HISTORY_DATA_BEGIN : 'DEMO_HAPPY_HISTORY_DATA_BEGIN',
    DEMO_HAPPY_HISTORY_DATA_SUCCESS : 'DEMO_HAPPY_HISTORY_DATA_SUCCESS',
    DEMO_HAPPY_HISTORY_DATA_ERR : 'DEMO_HAPPY_HISTORY_DATA_ERR',

    DEMO_HAPPY_SCHEDULED_LIST_DATA_BEGIN : 'DEMO_HAPPY_SCHEDULED_LIST_DATA_BEGIN',
    DEMO_HAPPY_SCHEDULED_LIST_DATA_SUCCESS : 'DEMO_HAPPY_SCHEDULED_LIST_DATA_SUCCESS',
    DEMO_HAPPY_SCHEDULED_LIST_DATA_ERR : 'DEMO_HAPPY_SCHEDULED_LIST_DATA_ERR',

    DEMO_HAPPY_PRIORITY_LIST_DATA_BEGIN : 'DEMO_HAPPY_PRIORITY_LIST_DATA_BEGIN',
    DEMO_HAPPY_PRIORITY_LIST_DATA_SUCCESS : 'DEMO_HAPPY_PRIORITY_LIST_DATA_SUCCESS',
    DEMO_HAPPY_PRIORITY_LIST_DATA_ERR : 'DEMO_HAPPY_PRIORITY_LIST_DATA_ERR',


    EXCEL_HEADER_DATA_BEGIN:'EXCEL_HEADER_DATA_BEGIN',
    EXCEL_HEADER_DATA_SUCCESS: 'EXCEL_HEADER_DATA_SUCCESS',
    EXCEL_HEADER_DATA_ERR: 'EXCEL_HEADER_DATA_ERR',

    SUBMIT_FILE_DATA_BEGIN: 'SUBMIT_FILE_DATA_BEGIN',
    SUBMIT_FILE_DATA_SUCCESS: 'SUBMIT_FILE_DATA_SUCCESS',
    SUBMIT_FILE_DATA_ERR: 'SUBMIT_FILE_DATA_ERR',

    DATA_DATA_BEGIN : 'DATA_DATA_BEGIN',
    DATA_DATA_SUCCESS: 'DATA_DATA_SUCCESS',
    DATA_DATA_ERR : 'DATA_DATA_ERR',

    DATA_SAVE_DATA_BEGIN : 'DATA_SAVE_DATA_BEGIN',
    DATA_SAVE_DATA_SUCCESS: 'DATA_SAVE_DATA_SUCCESS',
    DATA_SAVE_DATA_ERR : 'DATA_SAVE_DATA_ERR',

    DATA_DOWNLOAD_DATA_BEGIN : 'DATA_DOWNLOAD_DATA_BEGIN',
    DATA_DOWNLOAD_DATA_SUCCESS: 'DATA_DOWNLOAD_DATA_SUCCESS',
    DATA_DOWNLOAD_DATA_ERR : 'DATA_DOWNLOAD_DATA_ERR',

    DEMO_EXCEL_DATA_BEGIN : 'DEMO_EXCEL_DATA_BEGIN',
    DEMO_EXCEL_DATA_SUCCESS: 'DEMO_EXCEL_DATA_SUCCESS',
    DEMO_EXCEL_DATA_ERR : 'DEMO_EXCEL_DATA_ERR',


    DEMO_REGISTER_SAP_REPORT_DATE_DATA_BEGIN : 'DEMO_REGISTER_SAP_REPORT_DATE_DATA_BEGIN',
    DEMO_REGISTER_SAP_REPORT_DATE_DATA_SUCCESS: 'DEMO_REGISTER_SAP_REPORT_DATE_DATA_SUCCESS',
    DEMO_REGISTER_SAP_REPORT_DATE_DATA_ERR: 'DEMO_REGISTER_SAP_REPORT_DATE_DATA_ERR',


    requestTypeSubmittedListBegin: () => {
        return {
            type: actions.COMPLAINT_LIST_BEGIN,
        };
    },

    requestTypeSubmittedListSuccess: (data) => {
        return {
            type: actions.COMPLAINT_LIST_SUCCESS,
            data,
        };
    },

    requestTypeSubmittedListErr: (err) => {
        return {
            type: actions.COMPLAINT_LIST_ERR,
            err,
        };
    },

    happyComplaintListBegin: () => {
        return {
            type: actions.HAPPY_COMPLAINT_LIST_BEGIN,
        };
    },

    happyComplaintListSuccess: (data) => {
        return {
            type: actions.HAPPY_COMPLAINT_LIST_SUCCESS,
            data,
        };
    },

    happyComplaintListErr: (err) => {
        return {
            type: actions.HAPPY_COMPLAINT_LIST_ERR,
            err,
        };
    },

    getPriorityListBegin: () => {
        return {
            type: actions.GET_PRIORITY_LIST_BEGIN,
        };
    },

    getPriorityListSuccess: (data) => {
        return {
            type: actions.GET_PRIORITY_LIST_SUCCESS,
            data,
        };
    },

    getPriorityListErr: (err) => {
        return {
            type: actions.GET_PRIORITY_LIST_ERR,
            err,
        };
    },

    getScheduleListBegin: () => {
        return {
            type: actions.GET_SCHEDULE_LIST_BEGIN,
        };
    },

    getScheduleListSuccess: (data) => {
        return {
            type: actions.GET_SCHEDULE_LIST_SUCCESS,
            data,
        };
    },

    getScheduleListErr: (err) => {
        return {
            type: actions.GET_SCHEDULE_LIST_ERR,
            err,
        };
    },

    assignAgentComplaintDataBegin: () => {
        return {
            type: actions.ASSIGN_AGENT_COMPLAINT_DATA_BEGIN,
        };
    },

    assignAgentComplaintDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_AGENT_COMPLAINT_DATA_SUCCESS,
            data,
        };
    },

    assignAgentComplaintDataErr: (err) => {
        return {
            type: actions.ASSIGN_AGENT_COMPLAINT_DATA_ERR,
            err,
        };
    },
    
    assignAgentComplaintDataReset: (err) => {
        return {
            type: actions.ASSIGN_AGENT_COMPLAINT_DATA_RESET,
            err,
        };
    },

    unassignAgentComplaintDataBegin: () => {
        return {
            type: actions.UNASSIGN_AGENT_COMPLAINT_DATA_BEGIN,
        };
    },

    unassignAgentComplaintDataSuccess: (data) => {
        return {
            type: actions.UNASSIGN_AGENT_COMPLAINT_DATA_SUCCESS,
            data,
        };
    },

    unassignAgentComplaintDataErr: (err) => {
        return {
            type: actions.UNASSIGN_AGENT_COMPLAINT_DATA_ERR,
            err,
        };
    },
    
    unassignAgentComplaintDataReset: (err) => {
        return {
            type: actions.UNASSIGN_AGENT_COMPLAINT_DATA_RESET,
            err,
        };
    },

    getComplaintRegisterDataBegin: () => {
        return {
            type: actions.COMPLAINT_REGISTER_HISTORY_DATA_BEGIN,
        };
    },

    getComplaintRegisterDataSuccess: (data) => {
        return {
            type: actions.COMPLAINT_REGISTER_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    getComplaintRegisterDataErr: (err) => {
        return {
            type: actions.COMPLAINT_REGISTER_HISTORY_DATA_ERR,
            err,
        };
    },

    abandonedListDataBegin: () => {
        return {
            type: actions.ABANDONED_LIST_DATA_BEGIN,
        };
    },

    abandonedListDataSuccess: (data) => {
        return {
            type: actions.ABANDONED_LIST_DATA_SUCCESS,
            data,
        };
    },

    abandonedListDataErr: (err) => {
        return {
            type: actions.ABANDONED_LIST_DATA_ERR,
            err,
        };
    },

    assignAgentAbandonedDataBegin: () => {
        return {
            type: actions.ASSIGN_AGENT_ABANDONED_DATA_BEGIN,
        };
    },

    assignAgentAbandonedDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_AGENT_ABANDONED_DATA_SUCCESS,
            data,
        };
    },

    assignAgentAbandonedDataErr: (err) => {
        return {
            type: actions.ASSIGN_AGENT_ABANDONED_DATA_ERR,
            err,
        };
    },
    
    assignAgentAbandonedDataReset: (err) => {
        return {
            type: actions.ASSIGN_AGENT_ABANDONED_DATA_RESET,
            err,
        };
    },


    viewAbandonedDataBegin: () => {
        return {
            type: actions.VIEW_ABANDONED_DATA_BEGIN,
        };
    },

    viewAbandonedDataSuccess: (data) => {
        return {
            type: actions.VIEW_ABANDONED_DATA_SUCCESS,
            data,
        };
    },

    viewAbandonedDataErr: (err) => {
        return {
            type: actions.VIEW_ABANDONED_DATA_ERR,
            err,
        };
    },


    abandonedHistoryDataBegin: () => {
        return {
            type: actions.ABANDONED_HISTORY_DATA_BEGIN,
        };
    },

    abandonedHistoryDataSuccess: (data) => {
        return {
            type: actions.ABANDONED_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    abandonedHistoryDataErr: (err) => {
        return {
            type: actions.ABANDONED_HISTORY_DATA_ERR,
            err,
        };
    },

    mandatoryListDataBegin: () => {
        return {
            type: actions.MANDATORY_LIST_DATA_BEGIN,
        };
    },

    mandatoryListDataSuccess: (data) => {
        return {
            type: actions.MANDATORY_LIST_DATA_SUCCESS,
            data,
        };
    },

    mandatoryListDataErr: (err) => {
        return {
            type: actions.MANDATORY_LIST_DATA_ERR,
            err,
        };
    },

    assignMandatoryDataBegin: () => {
        return {
            type: actions.ASSIGN_MANDATORY_DATA_BEGIN,
        };
    },

    assignMandatoryDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_MANDATORY_DATA_SUCCESS,
            data,
        };
    },

    assignMandatoryDataErr: (err) => {
        return {
            type: actions.ASSIGN_MANDATORY_DATA_ERR,
            err,
        };
    },

    assignMandatoryDataReset: (err) => {
        return {
            type: actions.ASSIGN_MANDATORY_DATA_RESET,
            err,
        };
    },

    viewMandatoryDataBegin: () => {
        return {
            type: actions.VIEW_MANDATORY_DATA_BEGIN,
        };
    },

    viewMandatoryDataSuccess: (data) => {
        return {
            type: actions.VIEW_MANDATORY_DATA_SUCCESS,
            data,
        };
    },

    viewMandatoryDataErr: (err) => {
        return {
            type: actions.VIEW_MANDATORY_DATA_ERR,
            err,
        };
    },

    mandatoryHistoryDataBegin: () => {
        return {
            type: actions.MANDATORY_HISTORY_DATA_BEGIN,
        };
    },

    mandatoryHistoryDataSuccess: (data) => {
        return {
            type: actions.MANDATORY_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    mandatoryHistoryDataErr: (err) => {
        return {
            type: actions.MANDATORY_HISTORY_DATA_ERR,
            err,
        };
    },

    accessoriesTeleSalesListDataBegin: () => {
        return {
            type: actions.ACCESSORIES_TELE_SALES_LIST_DATA_BEGIN,
        };
    },

    accessoriesTeleSalesListDataSuccess: (data) => {
        return {
            type: actions.ACCESSORIES_TELE_SALES_LIST_DATA_SUCCESS,
            data,
        };
    },

    accessoriesTeleSalesListDataErr: (err) => {
        return {
            type: actions.ACCESSORIES_TELE_SALES_LIST_DATA_ERR,
            err,
        };
    },

    assignAccessoriesTeleSalesDataBegin: () => {
        return {
            type: actions.ASSIGN_ACCESSORIES_TELE_SALES_DATA_BEGIN,
        };
    },

    assignAccessoriesTeleSalesDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_ACCESSORIES_TELE_SALES_DATA_SUCCESS,
            data,
        };
    },

    assignAccessoriesTeleSalesDataErr: (err) => {
        return {
            type: actions.ASSIGN_ACCESSORIES_TELE_SALES_DATA_ERR,
            err,
        };
    },

    assignAccessoriesTeleSalesDataReset: (err) => {
        return {
            type: actions.ASSIGN_ACCESSORIES_TELE_SALES_DATA_RESET,
            err,
        };
    },

    viewAccessoriesTeleSalesDataBegin: () => {
        return {
            type: actions.VIEW_ACCESSORIES_TELE_SALES_DATA_BEGIN,
        };
    },

    viewAccessoriesTeleSalesDataSuccess: (data) => {
        return {
            type: actions.VIEW_ACCESSORIES_TELE_SALES_DATA_SUCCESS,
            data,
        };
    },

    viewAccessoriesTeleSalesDataErr: (err) => {
        return {
            type: actions.VIEW_ACCESSORIES_TELE_SALES_DATA_ERR,
            err,
        };
    },
    
    accessoriesTeleSalesHistoryDataBegin: () => {
        return {
            type: actions.ACCESSORIES_TELE_SALES_HISTORY_DATA_BEGIN,
        };
    },

    accessoriesTeleSalesHistoryDataSuccess: (data) => {
        return {
            type: actions.ACCESSORIES_TELE_SALES_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    accessoriesTeleSalesHistoryDataErr: (err) => {
        return {
            type: actions.ACCESSORIES_TELE_SALES_HISTORY_DATA_ERR,
            err,
        };
    },

    getAllDemoHappyListDataBegin: () => {
        return {
            type: actions.DEMO_HAPPY_LIST_DATA_BEGIN,
        };
    },
    
    getAllDemoHappyListDataSuccess: (data) => {
        return {
            type: actions.DEMO_HAPPY_LIST_DATA_SUCCESS,
            data,
        };
    },
    
    getAllDemoHappyListDataErr: (err) => {
        return {
            type: actions.DEMO_HAPPY_LIST_DATA_ERR,
            err,
        };
    },
    
    assignDemoHappyDetailsDataBegin: () => {
        return {
            type: actions.ASSIGN_DEMO_HAPPY_DATA_BEGIN,
        };
    },
    
    assignDemoHappyDetailsDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_DEMO_HAPPY_DATA_SUCCESS,
            data,
        };
    },
    
    assignDemoHappyDetailsDataErr: (err) => {
        return {
            type: actions.ASSIGN_DEMO_HAPPY_DATA_ERR,
            err,
        };
    },
    
    assignDemoHappyDetailsDataReset: (err) => {
        return {
            type: actions.ASSIGN_DEMO_HAPPY_DATA_RESET,
            err,
        };
    },
    
    viewDemoHappyDetailsDataBegin: () => {
        return {
            type: actions.VIEW_DEMO_HAPPY_DETAILS_DATA_BEGIN,
        };
    },
    
    viewDemoHappyDetailsDataSuccess: (data) => {
        return {
            type: actions.VIEW_DEMO_HAPPY_DETAILS_DATA_SUCCESS,
            data,
        };
    },
    
    viewDemoHappyDetailsDataErr: (err) => {
        return {
            type: actions.VIEW_DEMO_HAPPY_DETAILS_DATA_ERR,
            err,
        };
    },
        
    getDemoHappyHistoryDataBegin: () => {
        return {
            type: actions.DEMO_HAPPY_HISTORY_DATA_BEGIN,
        };
    },
    
    getDemoHappyHistoryDataSuccess: (data) => {
        return {
            type: actions.DEMO_HAPPY_HISTORY_DATA_SUCCESS,
            data,
        };
    },
    
    getDemoHappyHistoryDataErr: (err) => {
        return {
            type: actions.DEMO_HAPPY_HISTORY_DATA_ERR,
            err,
        };
    },
    
    getAllDemoHappyScheduledListDataBegin: () => {
        return {
            type: actions.DEMO_HAPPY_SCHEDULED_LIST_DATA_BEGIN,
        };
    },
    
    getAllDemoHappyScheduledListDataSuccess: (data) => {
        return {
            type: actions.DEMO_HAPPY_SCHEDULED_LIST_DATA_SUCCESS,
            data,
        };
    },
    
    getAllDemoHappyScheduledListDataErr: (err) => {
        return {
            type: actions.DEMO_HAPPY_SCHEDULED_LIST_DATA_ERR,
            err,
        };
    },
    
    getAllDemoHappyPriorityListDataBegin: () => {
        return {
            type: actions.DEMO_HAPPY_PRIORITY_LIST_DATA_BEGIN,
        };
    },
    
    getAllDemoHappyPriorityListDataSuccess: (data) => {
        return {
            type: actions.DEMO_HAPPY_PRIORITY_LIST_DATA_SUCCESS,
            data,
        };
    },
    
    getAllDemoHappyPriorityListDataErr: (err) => {
        return {
            type: actions.DEMO_HAPPY_PRIORITY_LIST_DATA_ERR,
            err,
        };
    },


    excelHeaderDataBegin: () => {
        return {
            type: actions.EXCEL_HEADER_DATA_BEGIN,
        };
    },

    excelHeaderDataSuccess: (data) => {
        return {
            type: actions.EXCEL_HEADER_DATA_SUCCESS,
            data,
        };
    },

    excelHeaderDataErr: (err) => {
        return {
            type: actions.EXCEL_HEADER_DATA_ERR,
            err,
        };
    },

    submitDemoDataBegin: () => {
        return {
            type: actions.SUBMIT_FILE_DATA_BEGIN,
        };
    },

    submitDemoDataSuccess: (data) => {
        return {
            type: actions.SUBMIT_FILE_DATA_SUCCESS,
            data,
        };
    },

    submitDemoDataErr: (err) => {
        return {
            type: actions.SUBMIT_FILE_DATA_ERR,
            err,
        };
    },

    dataReportDataBegin: () => {
        return {
            type: actions.DATA_DATA_BEGIN,
        };
    },

    dataReportDataSuccess: (data) => {
        return {
            type: actions.DATA_DATA_SUCCESS,
            data,
        };
    },

    dataReportDataErr: (err) => {
        return {
            type: actions.DATA_DATA_ERR,
            err,
        };
    },

    dataReportSaveDataBegin: () => {
        return {
            type: actions.DATA_SAVE_DATA_BEGIN,
        };
    },

    dataReportSaveDataSuccess: (data) => {
        return {
            type: actions.DATA_SAVE_DATA_SUCCESS,
            data,
        };
    },

    dataReportSaveDataErr: (err) => {
        return {
            type: actions.DATA_SAVE_DATA_ERR,
            err,
        };
    },

    dataReportDownloadDataBegin: () => {
        return {
            type: actions.DATA_DOWNLOAD_DATA_BEGIN,
        };
    },

    dataReportDownloadDataSuccess: (data) => {
        return {
            type: actions.DATA_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    dataReportDownloadDataErr: (err) => {
        return {
            type: actions.DATA_DOWNLOAD_DATA_ERR,
            err,
        };
    },

    demoExcelDataBegin: () => {
        return {
            type: actions.DEMO_EXCEL_DATA_BEGIN,
        };
    },

    demoExcelDataSuccess: (data) => {
        return {
            type: actions.DEMO_EXCEL_DATA_SUCCESS,
            data,
        };
    },

    demoExcelDataErr: (err) => {
        return {
            type: actions.DEMO_EXCEL_DATA_ERR,
            err,
        };
    },


    
    getDemoRegisterSAPDateRangeDataBegin: () => {
        return {
            type: actions.DEMO_REGISTER_SAP_REPORT_DATE_DATA_BEGIN,
        };
    },

    getDemoRegisterSAPDateRangeDataSuccess: (data) => {
        return {
            type: actions.DEMO_REGISTER_SAP_REPORT_DATE_DATA_SUCCESS,
            data,
        };
    },

    getDemoRegisterSAPDateRangeDataErr: (err) => {
        return {
            type: actions.DEMO_REGISTER_SAP_REPORT_DATE_DATA_ERR,
            err,
        };
    },

};

export default actions;
