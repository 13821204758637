import { combineReducers } from 'redux';

import { authReducer, forgotReducer } from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import chartContentReducer from './chartContent/reducers';

import {storeReducer, storeReportReducers, storeReportSaveReducers, storeReportDownloadReducers} from './store/reducers';
import { customerReducer, customerSalesReducer, customerReportReducers, customerReportSaveReducers, customerReportDownloadReducers  } from './customer/reducers';
import { inventoryReducer, inventoryReportReducers, inventoryReportSaveReducers, inventoryReportDownloadReducers  } from './inventory/reducers';

import { salesReducer, salesReportReducers, salesReportSaveReducers, salesReportDownloadReducers }  from './sales/reducers';
import { employeeDashbaordReducers,employeeReducers, updateEmployeeUserTypeReducers, updateEmployeeLoginStatusReducers, updateEmployeeStatusReducers, updateEmployeePasswordReducers, employeeReportReducers, employeeReportSaveReducers, employeeReportDownloadReducers, employeeManualSyncReducers,employeeManualCreateReducers, employeeManualEditReducers,vendorPincodeListReducers } from './employee/reducers';
import { categoryReducers, brandReducers, branchReducers, storeListPincodeReducers,categoryBrandModelReducers, stockEmployeeListReducers, maintenanceEmployeeListReducers,vendorEmployeeListReducers, stateListReducers, languageListReducers } from './common/reducers';
import { agentCurrentStatusReducers,agentUpdateStatusReducers, agentUpdateCallStatusReducers, agentClicktoCallStatusReducers, agentSubmitCallStatusReducers, agentListReducers, submitPurchaseEnquiryReducers, resubmitPurchaseEnquiryReducers, agentWalkoutLeadReducers, agentPurchaseLeadReducers, agentPurchaseEditLeadReducers, agentNumberReducers, sipNumberReducers, dispositionRequestTypeReducers, getRequestTypesReducers, submitRegisterEnquiryReducers, agentComplaintListReducers, requestSubmittedDetailReducers, agentHappydemoComplaintReducers, updateComplaintIDReducers, updatePriorityComplaintIDReducers, updatePriorityToScheduledReducers, getRegsiterDispositionReducers, updateRegisterStatusReducers, getAgentPriorityListReducers, getAgentScheduleListReducers, getLegalScheduleListReducers,  getAbandonedReducers, getMandatoryReducers, getAbandonedDispositionReducers,updateAbandonedStatusReducers,getMandatoryDispositionReducers, updateMandatoryStatusReducers, getAccessoriesTeleSalesReducers,getAccessoriesTeleSalesDispositionReducers, getAccessoriesTeleSalesCategoryDispositionReducers, updateAccessoriesTeleSalesStatusReducers, getDemoHappySapReducers, updateDemoHappySapStatusReducers, getDemoHappySapScheduledReducers,  getDemoHappySapPrioityReducers, getAgentCallPopupCustomerReducers, agentManualClicktoCallStatusReducers, getReferenceDetailReducers, getDownloadComplaintDetailReducers, getAgentBasedCallRecordReducers, getDemoListReducers, agentReportReducers, agentReportSaveReducers, agentReportDownloadReducers, stopTimerReducer, submitReviewReducers,agentBasedReviewReducers  } from './agent/reducers';
import { channelReducers, liveCallListReducers, callMoniterReportReducers, callMargeReportReducers, incomingVOIPReportReducers, signInSignOutReportReducers, hoursReportReducers, callActionReducers, requestTypeReducers,requestTypeSubmitReducers, subRequestTypeReducers,subRequestTypeSubmitReducers,  agentForceLogoutReducers, liveCountSelfReducers, liveCountBlrReducers, customercareReportReducers,customercareReportSaveReducers, customercareReportDownloadReducers, updateEmployeeLanguageReducers } from './customercare/reducers';
import { purchaseDashbaordLeadSourceReducers, purchaseDashboardCategoryReducers, purchaseDashboardTicketReducers, purchaseDashbaordSummaryReducers, purchaseDashbaordRegionReducers, purchaseEnquiresReducers, purchaseEnquiresDetailsReducers, purchaseEnquiresStatusReducers, purchaseReasonReducers, purchaseLeadSourceReducers, purchaseHistoryReducers, recheckPurchaseHistoryReducers, purchaseAddNotesReducers, purchaseReportReducers, purchaseReportSaveReducers,purchaseReportDownloadReducers } from './purchaseQueries/reducers';
import { walkoutDashbaordLeadSourceReducers, walkoutDashboardCategoryReducers, walkoutDashboardTicketReducers, walkoutDashbaordSummaryReducers, walkoutDashbaordRegionReducers, walkoutEnquiresReducers, walkoutEnquiresStatusReducers, walkoutReasonReducers, walkoutHistoryReducers, walkoutDispositionReducer, walkoutAddNotesReducers, walkoutLeadAssignReducers,walkoutLeadAssignBulkReducers, walkoutReportReducers, walkoutReportSaveReducers,walkoutReportDownloadReducers,zeroWalkoutReportReducers  } from './walkout/reducers';
import { maintenanceDashbaordLeadSourceReducers, maintenanceDashboardCategoryReducers, maintenanceDashboardTicketReducers, maintenanceDashbaordSummaryReducers, maintenanceDashbaordRegionReducers, maintenanceDepartmentReducer, maintenanceListReducers, maintenanceReportReducers, maintenanceReportSaveReducers,maintenanceReportDownloadReducers,maintenanceLeadAssignReducers, maintenanceEmployeeBasedListReducers,updateMaintenanceStatusReducers,maintenanceStatusHisoryReducers, maintenanceEmployeeReportReducers, maintenanceEmployeeReportSaveReducers, maintenanceEmployeeReportDownloadReducers } from './maintenance/reducers';
import { requestTypeSubmittedListReducers, happydemoComplaintReducers, getPriorityListReducers, getScheduleListReducers, assignAgentComplaintReducers,complaintRegisterHistoryReducers, unassignAgentComplaintReducers,abandonedListReducers, assignAgentAbandonedReducers, viewAbandonedReducers, abandonedHistoryReducers, mandatoryListReducers, assignMandatoryReducers, viewMandatoryReducers, mandatoryHistoryReducers, accessoriesTeleSalesListReducers,viewAccessoriesTeleSalesReducers,assignAccessoriesTeleSalesReducers,accessoriesTeleSalesHistoryReducers, demoHappySapListReducers,viewDemoHappySapReducers,assignDemoHappySapReducers,demoHappySapHistoryReducers,demoHappySapScheduledListReducers,demoHappySapPriorityListReducers, excelHeaderReducers, submitFileReducers, demoExcelReducers, dataReportReducers, dataReportSaveReducers, dataReportDownloadReducers, demoRegisterSAPDateReportReducers  } from './datamanagement/reducers';
import { reviewDashbaordLeadSourceReducers, reviewDashboardCategoryReducers, reviewDashboardTicketReducers, reviewDashbaordSummaryReducers, reviewDashbaordRegionReducers, reviewEnquiresReducers, reviewViewDetailsReducers, reviewHistoryReducers, assignAgentReviewReducers, updateAgentReviewReducers, reviewReportReducers, reviewReportSaveReducers, reviewReportDownloadReducers  } from './review/reducers';
import { escalationDashbaordLeadSourceReducers, escalationDashboardCategoryReducers, escalationDashboardTicketReducers, escalationDashbaordSummaryReducers, escalationDashbaordRegionReducers, escalationDepartmentReducer, escalationListReducers, escalationLeadAssignReducers, escalationAgentBasedListReducers, updateEscalationStatusReducers, viewEscalactionReducers, escalationStatusHisoryReducers, escalationReportReducers, escalationReportSaveReducers, escalationReportDownloadReducers } from './escalation/reducers';
import { defectiveListReducers, assignAgentDefectiveReducers, viewDefectiveReducers, editDefectiveReducers, defectiveHistoryReducers, excelDefectiveHeaderReducers, submitDefectiveFileReducers, defectiveExcelReducers, defectiveReportReducers, defectiveReportSaveReducers, defectiveReportDownloadReducers  } from './stockDefective/reducers';
import { qualityDashbaordLeadSourceReducers, qualityDashboardCategoryReducers, qualityDashboardTicketReducers, qualityDashbaordSummaryReducers, qualityDashbaordRegionReducers, qualityFormReducers, qualityFormSubmitReducers, getAuditQualityListReducers, submitAuditQualityShareAgentReducers, getAgentBasedQualityListReducers, viewAuditQualityDetailsReducers, qualityReportReducers, qualityReportSaveReducers, qualityReportDownloadReducers } from './quality/reducers'
import { outofwarrantyListReducers, outofwarrantyScheduledListReducers, assignVendorOutofwarrantyReducers, outofwarrantyVendorListReducers,   outofwarrantyVendorScheduledListReducers, outofwarrantyDispositionListReducers, outofwarrantyHistoryReducers,viewOutofWarrantyReducers,vendorUpdateStatusReducers, outofwarrantyReportReducers, outofwarrantyReportSaveReducers, outofwarrantyReportDownloadReducers,  } from './outofwarranty/reducers';
import { extendedwarrantyPurchaseListReducers,extendedwarrantyListReducers,extendedwarrantyScheduledListReducers,extendedwarrantyPriorityListReducers, assignVendorExtendedWarrantyReducers, extendedwarrantyVendorListReducers,  extendedwarrantyVendorScheduledListReducers,extendedwarrantyVendorPriorityListReducers, extendedwarrantyVendorDispositionListReducers, assignAgentExtendedWarrantyReducers, extendedwarrantyAgentListReducers,  extendedwarrantyAgentScheduledListReducers,extendedwarrantyAgentPriorityListReducers, extendedwarrantyAgentDispositionListReducers,extendedwarrantyHistoryReducers,viewExtendWarrantyReducers,updateStatusReducers,extendedwarrantyReportReducers, extendedwarrantyReportSaveReducers, extendedwarrantyReportDownloadReducers } from './extendedwarranty/reducers'
import { serviceTypeListReducers, matrixTypeListReducers, serviceMatrixReducers, addServiceMatrixReducers, editServiceMatrixReducers, deleteServiceMatrixReducers, serviceMatrixDetailsReducers, serviceMatrixReportReducers, serviceMatrixReportSaveReducers, serviceMatrixReportDownloadReducers } from './serviceMatrix/reducers'


const rootReducers = combineReducers({
    // Base Reducers
    auth: authReducer,
    forgot:forgotReducer,
    ChangeLayoutMode,
    chartContent: chartContentReducer,
    
    // Sales Reducers
    sales: salesReducer,
    salesReport:salesReportReducers, 
    salesReportSave:salesReportSaveReducers, 
    salesReportDownload:salesReportDownloadReducers,

    // Store Reducers
    store: storeReducer,
    storeReport:storeReportReducers, 
    storeReportSave:storeReportSaveReducers, 
    storeReportDownload:storeReportDownloadReducers,

    // Customer Reducers
    customer:customerReducer,
    customerSales:customerSalesReducer,
    customerReport:customerReportReducers, 
    customerReportSave:customerReportSaveReducers, 
    customerReportDownload:customerReportDownloadReducers,
    
    // Inventory Reducers
    inventoryStock: inventoryReducer,
    inventoryReport:inventoryReportReducers, 
    inventoryReportSave:inventoryReportSaveReducers, 
    inventoryReportDownload:inventoryReportDownloadReducers ,
   
    // Common Reducers
    category:categoryReducers,
    brand:brandReducers,
    branch:branchReducers,
    storePincode: storeListPincodeReducers,
    categoryBrandModel:categoryBrandModelReducers,
    stockEmployeeList:stockEmployeeListReducers,
    maintenanceEmployeeList:maintenanceEmployeeListReducers,
    vendorEmployeeList:vendorEmployeeListReducers,
    stateList:stateListReducers,
    languageList:languageListReducers,
    
    // Agent Reducers
    agentStatus:agentCurrentStatusReducers,
    agentUpdateStatus:agentUpdateStatusReducers,
    agentUpdateCallStatus:agentUpdateCallStatusReducers,
    agentClicktoCallStatus:agentClicktoCallStatusReducers,
    agentManualClicktoCallStatus:agentManualClicktoCallStatusReducers,
    agentSubmitCallStatus:agentSubmitCallStatusReducers,
    agentList:agentListReducers,
    submitPurchaseEnquiry:submitPurchaseEnquiryReducers,
    agentWalkoutLead:agentWalkoutLeadReducers,
    resubmitPurchaseEnquiry:resubmitPurchaseEnquiryReducers,
    agentPurchaseLead:agentPurchaseLeadReducers,
    agentPurchaseEditLead:agentPurchaseEditLeadReducers,
    agentNumber:agentNumberReducers,
    sipNumber:sipNumberReducers,
    dispositionRequestType:dispositionRequestTypeReducers,
    getRequestTypes:getRequestTypesReducers, 
    submitRegisterEnquiry:submitRegisterEnquiryReducers,
    requestSubmittedDetail:requestSubmittedDetailReducers,
    agentComplaintList:agentComplaintListReducers,
    agentHappydemoComplaint:agentHappydemoComplaintReducers,
    updateComplaintID:updateComplaintIDReducers, 
    updatePriorityComplaintID:updatePriorityComplaintIDReducers,
    updatePriorityToScheduled:updatePriorityToScheduledReducers,
    getRegsiterDisposition:getRegsiterDispositionReducers, 
    updateRegisterStatus:updateRegisterStatusReducers, 
    getAgentPriorityList:getAgentPriorityListReducers, 
    getAgentScheduleList:getAgentScheduleListReducers,
    getLegalScheduleList:getLegalScheduleListReducers,
    getDemoList:getDemoListReducers,
    getAbandoned:getAbandonedReducers,
    getAbandonedDisposition:getAbandonedDispositionReducers,
    updateAbandonedStatus:updateAbandonedStatusReducers,
    getMandatoryDisposition:getMandatoryDispositionReducers, 
    updateMandatoryStatus:updateMandatoryStatusReducers,
    getMandatory:getMandatoryReducers,
    getAccessoriesTeleSales:getAccessoriesTeleSalesReducers,
    getAccessoriesTeleSalesDisposition:getAccessoriesTeleSalesDispositionReducers,
    getAccessoriesTeleSalesCategoryDisposition:getAccessoriesTeleSalesCategoryDispositionReducers,
    updateAccessoriesTeleSalesStatus:updateAccessoriesTeleSalesStatusReducers,
    getDemoHappySap:getDemoHappySapReducers, 
    updateDemoHappySapStatus:updateDemoHappySapStatusReducers, 
    getDemoHappySapScheduled:getDemoHappySapScheduledReducers,  
    getDemoHappySapPrioity:getDemoHappySapPrioityReducers,
    getAgentCallPopupCustomer:getAgentCallPopupCustomerReducers,
    getDownloadComplaintDetail:getDownloadComplaintDetailReducers,
    getReferenceDetail: getReferenceDetailReducers,
    getAgentBasedCallRecord:getAgentBasedCallRecordReducers,
    agentReport:agentReportReducers,
    agentReportSave:agentReportSaveReducers,
    agentReportDownload:agentReportDownloadReducers,
    stopTimer:stopTimerReducer,
    submitReview:submitReviewReducers,
    agentBasedReview:agentBasedReviewReducers,

    // Customer Care Reducers
    channel: channelReducers, 
    liveCallList:liveCallListReducers, 
    callMoniterReport:callMoniterReportReducers, 
    callMargeReport:callMargeReportReducers, 
    incomingVOIPReport:incomingVOIPReportReducers, 
    signInSignOutReport:signInSignOutReportReducers, 
    callAction:callActionReducers,
    hoursReport:hoursReportReducers,
    requestType:requestTypeReducers,
    requestTypeSubmit:requestTypeSubmitReducers,
    subRequestType:subRequestTypeReducers,
    subRequestTypeSubmit:subRequestTypeSubmitReducers,
    agentForceLogout:agentForceLogoutReducers,
    liveCountSelf:liveCountSelfReducers,
    liveCountBlr:liveCountBlrReducers,
    customercareReport:customercareReportReducers, 
    customercareReportSave:customercareReportSaveReducers, 
    customercareReportDownload:customercareReportDownloadReducers,
    updateEmployeeLanguage:updateEmployeeLanguageReducers,
  
    // Employee Reducers
    employee: employeeReducers,
    employeeDashboard:employeeDashbaordReducers,
    employeeUserType:updateEmployeeUserTypeReducers,
    employeeLoginStatus:updateEmployeeLoginStatusReducers,
    employeeStatus:updateEmployeeStatusReducers,
    employeePassword:updateEmployeePasswordReducers,
    employeeReport:employeeReportReducers,
    employeeReportSave:employeeReportSaveReducers, 
    employeeReportDownload:employeeReportDownloadReducers,
    employeeManualSync:employeeManualSyncReducers,
    employeeManualCreate:employeeManualCreateReducers,
    employeeManualEdit:employeeManualEditReducers,
    vendorPincodeList:vendorPincodeListReducers,

    // Purchase Reducers
    purchaseDashbaordLeadSource:purchaseDashbaordLeadSourceReducers, 
    purchaseDashboardCategory:purchaseDashboardCategoryReducers, 
    purchaseDashboardTicket:purchaseDashboardTicketReducers, 
    purchaseDashbaordSummary:purchaseDashbaordSummaryReducers,
    purchaseDashbaordRegion:purchaseDashbaordRegionReducers,
    purchaseEnquires:purchaseEnquiresReducers,
    purchaseEnquiresDetails:purchaseEnquiresDetailsReducers,
    purchaseEnquiresStatus:purchaseEnquiresStatusReducers,
    purchaseReason:purchaseReasonReducers,
    purchaseLeadSource:purchaseLeadSourceReducers,
    purchaseHistory:purchaseHistoryReducers,
    recheckPurchaseHistory:recheckPurchaseHistoryReducers,
    purchaseAddNotes:purchaseAddNotesReducers,
    purchaseReport:purchaseReportReducers,
    purchaseReportSave:purchaseReportSaveReducers,
    purchaseReportDownload:purchaseReportDownloadReducers,

    // Walkout Reducers
    walkoutDashbaordLeadSource:walkoutDashbaordLeadSourceReducers, 
    walkoutDashboardCategory:walkoutDashboardCategoryReducers, 
    walkoutDashboardTicket:walkoutDashboardTicketReducers, 
    walkoutDashbaordSummary:walkoutDashbaordSummaryReducers,
    walkoutDashbaordRegion:walkoutDashbaordRegionReducers,
    walkoutEnquires:walkoutEnquiresReducers,
    walkoutEnquiresStatus:walkoutEnquiresStatusReducers,
    walkoutReason:walkoutReasonReducers,
    walkoutHistory:walkoutHistoryReducers,
    walkoutDisposition:walkoutDispositionReducer,
    walkoutAddNotes:walkoutAddNotesReducers,
    walkoutLeadAssign: walkoutLeadAssignReducers,
    walkoutLeadAssignBulk:walkoutLeadAssignBulkReducers,
    walkoutReport:walkoutReportReducers,
    walkoutReportSave:walkoutReportSaveReducers,
    walkoutReportDownload:walkoutReportDownloadReducers,
    zeroWalkoutReport:zeroWalkoutReportReducers,

    // Data Management Defective
    requestSubmittedList:requestTypeSubmittedListReducers,
    happydemoComplaint:happydemoComplaintReducers,
    getPriorityList:getPriorityListReducers, 
    getScheduleList:getScheduleListReducers,

    assignAgentComplaint:assignAgentComplaintReducers,
    unassignAgentComplaint:unassignAgentComplaintReducers,
    complaintRegisterHistory:complaintRegisterHistoryReducers,

    abandonedList:abandonedListReducers,
    assignAgentAbandoned:assignAgentAbandonedReducers,
    viewAbandoned:viewAbandonedReducers, 
    abandonedHistory:abandonedHistoryReducers,

    mandatoryList:mandatoryListReducers,
    assignMandatory:assignMandatoryReducers,
    viewMandatory:viewMandatoryReducers, 
    mandatoryHistory:mandatoryHistoryReducers,

    accessoriesTeleSalesList:accessoriesTeleSalesListReducers,
    viewAccessoriesTeleSales:viewAccessoriesTeleSalesReducers,
    assignAccessoriesTeleSales:assignAccessoriesTeleSalesReducers,
    accessoriesTeleSalesHistory:accessoriesTeleSalesHistoryReducers,
    
    demoHappySapList:demoHappySapListReducers,
    viewDemoHappySap:viewDemoHappySapReducers,
    assignDemoHappySap:assignDemoHappySapReducers,
    demoHappyHistorySap:demoHappySapHistoryReducers,
    demoHappySapScheduledList:demoHappySapScheduledListReducers,
    demoHappySapPriorityList:demoHappySapPriorityListReducers,

    demoRegisterSAPDateReport:demoRegisterSAPDateReportReducers,
    
    excelHeader:excelHeaderReducers,
    submitFile:submitFileReducers,
    demoExcel:demoExcelReducers,
    dataReport:dataReportReducers,
    dataReportSave:dataReportSaveReducers,
    dataReportDownload:dataReportDownloadReducers,

    // Maintenance
    maintenanceDashbaordLeadSource:maintenanceDashbaordLeadSourceReducers, 
    maintenanceDashboardCategory:maintenanceDashboardCategoryReducers, 
    maintenanceDashboardTicket:maintenanceDashboardTicketReducers, 
    maintenanceDashbaordSummary:maintenanceDashbaordSummaryReducers,
    maintenanceDashbaordRegion:maintenanceDashbaordRegionReducers,
    maintenanceDepartment:maintenanceDepartmentReducer,
    maintenanceList:maintenanceListReducers,
    maintenanceLeadAssign:maintenanceLeadAssignReducers,
    maintenanceEmployeeBasedList:maintenanceEmployeeBasedListReducers,
    updateMaintenanceStatus:updateMaintenanceStatusReducers,
    maintenanceStatusHisory:maintenanceStatusHisoryReducers,
    maintenanceReport:maintenanceReportReducers, 
    maintenanceReportSave:maintenanceReportSaveReducers,
    maintenanceReportDownload:maintenanceReportDownloadReducers,
    maintenanceEmployeeReport:maintenanceEmployeeReportReducers, 
    maintenanceEmployeeReportSave:maintenanceEmployeeReportSaveReducers, 
    maintenanceEmployeeReportDownload:maintenanceEmployeeReportDownloadReducers,
    

    // Review
    reviewDashbaordLeadSource:reviewDashbaordLeadSourceReducers, 
    reviewDashboardCategory:reviewDashboardCategoryReducers, 
    reviewDashboardTicket:reviewDashboardTicketReducers, 
    reviewDashbaordSummary:reviewDashbaordSummaryReducers, 
    reviewDashbaordRegion:reviewDashbaordRegionReducers, 
    reviewEnquires:reviewEnquiresReducers, 
    reviewViewDetails:reviewViewDetailsReducers, 
    reviewHistory:reviewHistoryReducers, 
    assignAgentReview:assignAgentReviewReducers,
    updateAgentReview:updateAgentReviewReducers,
    reviewReport:reviewReportReducers, 
    reviewReportSave:reviewReportSaveReducers, 
    reviewReportDownload:reviewReportDownloadReducers,

    // Escalation
    escalationDashbaordLeadSource:escalationDashbaordLeadSourceReducers,
    escalationDashboardCategory:escalationDashboardCategoryReducers, 
    escalationDashboardTicket:escalationDashboardTicketReducers, 
    escalationDashbaordSummary:escalationDashbaordSummaryReducers, 
    escalationDashbaordRegion:escalationDashbaordRegionReducers, 
    escalationDepartment:escalationDepartmentReducer,  
    escalationList:escalationListReducers, 
    escalationLeadAssign:escalationLeadAssignReducers,
    escalationAgentBasedList:escalationAgentBasedListReducers,
    updateEscalationStatus:updateEscalationStatusReducers, 
    viewEscalaction:viewEscalactionReducers,
    escalationStatusHisory:escalationStatusHisoryReducers,
    escalationReport:escalationReportReducers, 
    escalationReportSave:escalationReportSaveReducers, 
    escalationReportDownload:escalationReportDownloadReducers, 

    // stock Defective
    defectiveList:defectiveListReducers, 
    assignAgentDefective:assignAgentDefectiveReducers, 
    viewDefective:viewDefectiveReducers, 
    editDefective:editDefectiveReducers,
    defectiveHistory:defectiveHistoryReducers,
    excelDefectiveHeader:excelDefectiveHeaderReducers,
    submitDefectiveFile:submitDefectiveFileReducers,
    defectiveExcel:defectiveExcelReducers,
    defectiveReport:defectiveReportReducers,
    defectiveReportSave:defectiveReportSaveReducers,
    defectiveReportDownload:defectiveReportDownloadReducers,

    // Quality
    qualityDashbaordLeadSource:qualityDashbaordLeadSourceReducers, 
    qualityDashboardCategory:qualityDashboardCategoryReducers, 
    qualityDashboardTicket:qualityDashboardTicketReducers, 
    qualityDashbaordSummary:qualityDashbaordSummaryReducers, 
    qualityDashbaordRegion:qualityDashbaordRegionReducers, 
    qualityForm:qualityFormReducers, 
    qualityFormSubmit:qualityFormSubmitReducers,
    getAuditQualityList:getAuditQualityListReducers, 
    submitAuditQualityShareAgent:submitAuditQualityShareAgentReducers, 
    getAgentBasedQualityList:getAgentBasedQualityListReducers, 
    viewAuditQualityDetails:viewAuditQualityDetailsReducers,
    qualityReport:qualityReportReducers, 
    qualityReportSave:qualityReportSaveReducers, 
    qualityReportDownload:qualityReportDownloadReducers,

    // OutofWarranty
    outofwarrantyList:outofwarrantyListReducers, 
    outofwarrantyScheduledList:outofwarrantyScheduledListReducers, 
    assignVendorOutofwarranty:assignVendorOutofwarrantyReducers, 
    outofwarrantyVendorList:outofwarrantyVendorListReducers,   
    outofwarrantyVendorScheduledList:outofwarrantyVendorScheduledListReducers, 
    outofwarrantyDispositionList:outofwarrantyDispositionListReducers,
    outofwarrantyHistory:outofwarrantyHistoryReducers,
    viewOutofWarranty:viewOutofWarrantyReducers,
    vendorUpdateStatus:vendorUpdateStatusReducers, 
    outofwarrantyReport:outofwarrantyReportReducers, 
    outofwarrantyReportSave:outofwarrantyReportSaveReducers, 
    outofwarrantyReportDownload:outofwarrantyReportDownloadReducers,
    

    // Extended Warranty
    extendedwarrantyPurchaseList:extendedwarrantyPurchaseListReducers,
    extendedwarrantyList:extendedwarrantyListReducers,
    extendedwarrantyScheduledList:extendedwarrantyScheduledListReducers,
    extendedwarrantyPriorityList:extendedwarrantyPriorityListReducers,
    assignVendorExtendedWarranty:assignVendorExtendedWarrantyReducers, 
    extendedwarrantyVendorList:extendedwarrantyVendorListReducers,  
    extendedwarrantyVendorScheduledList:extendedwarrantyVendorScheduledListReducers,
    extendedwarrantyVendorPriorityList:extendedwarrantyVendorPriorityListReducers, 
    extendedwarrantyVendorDispositionList:extendedwarrantyVendorDispositionListReducers,
    assignAgentExtendedWarranty:assignAgentExtendedWarrantyReducers, 
    extendedwarrantyAgentList:extendedwarrantyAgentListReducers,  
    extendedwarrantyAgentScheduledList:extendedwarrantyAgentScheduledListReducers,
    extendedwarrantyAgentPriorityList:extendedwarrantyAgentPriorityListReducers, 
    extendedwarrantyAgentDispositionList:extendedwarrantyAgentDispositionListReducers,
    extendedwarrantyHistory:extendedwarrantyHistoryReducers,
    viewExtendWarranty:viewExtendWarrantyReducers,
    updateStatus:updateStatusReducers,
    extendedwarrantyReport:extendedwarrantyReportReducers, 
    extendedwarrantyReportSave:extendedwarrantyReportSaveReducers, 
    extendedwarrantyReportDownload:extendedwarrantyReportDownloadReducers, 


    // Service Matrix
    serviceTypeList:serviceTypeListReducers,
    matrixTypeList:matrixTypeListReducers,
    serviceMatrix:serviceMatrixReducers, 
    addServiceMatrix:addServiceMatrixReducers, 
    editServiceMatrix:editServiceMatrixReducers,
    deleteServiceMatrix:deleteServiceMatrixReducers, 
    serviceMatrixDetails:serviceMatrixDetailsReducers, 
    serviceMatrixReport:serviceMatrixReportReducers, 
    serviceMatrixReportSave:serviceMatrixReportSaveReducers, 
    serviceMatrixReportDownload:serviceMatrixReportDownloadReducers

});

export default rootReducers;
