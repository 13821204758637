import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import WithAdminLayout from '../../layout/withAdminLayout';
import ChangeCallDirection from '../../container/agent/ChangeCallDirection';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import BreakTimer from '../../container/agent/BreakTimer';
import { useLocation } from 'react-router-dom';


const Dashboard                 = lazy(() => import('../../container/agent/Dashboard'));
const SearchCustomer             = lazy(() => import('../../container/agent/SearchCustomer'));
const SearchReference           = lazy(() => import('../../container/agent/SearchReference'));
const ChangeCall                = lazy(() => import('../../container/agent/ChangeCallDirection'));

const WalkoutList               = lazy(() => import('../../container/agent/WalkoutList'));
const WalkoutView               = lazy(() => import('../../container/agent/ViewWalkout'));

const PurchaseEnqueriesList     = lazy(() => import('../../container/agent/PurchaseEnqueriesList'));
const EditPurchaseEnquiry       = lazy(() => import('../../container/agent/EditPurchaseEnquiry'))
const AddPurchaseEnquiry        = lazy(() => import('../../container/agent/AddPurchaseEnquiry'))


const AddReview                 = lazy(() => import('../../container/agent/AddReview'));
const ReviewList                = lazy(() => import('../../container/agent/ReviewList'));
const ViewReview                = lazy(() => import('../../container/agent/ViewReview'));

const RegisteredList            = lazy(() => import('../../container/agent/RegisteredList'));
const ViewDetails               = lazy(() => import('../../container/agent/ViewDetails'));
const EditDetails               = lazy(() => import('../../container/agent/EditComplaintDetails'))
const ViewPurchaseDetails       = lazy(() => import('../../container/agent/ViewPurchaseDetails'));

const DemoHappy                 = lazy(() => import('../../container/agent/DemoHappy'));
const DemoHappySAP              = lazy(() => import('../../container/agent/DemoHappySAP'));
// const ComplaintsRegister     = lazy(() => import('../../container/agent/ComplaintRegister'));
const ComplaintsHappy           = lazy(() => import('../../container/agent/ComplaintHappy'));
const FurnitureComplaintHappy   = lazy(() => import('../../container/agent/FurnitureComplaintHappy'));
const LegalHappy                = lazy(() => import('../../container/agent/LegalHappy'));
const DefectiveUnitDeliveredHappy = lazy(() => import('../../container/agent/DefectiveUnitDeliveredHappy'));
const ViewHappyDetails          = lazy(() => import('../../container/agent/ViewHappyDetails'));
const ViewHappySAPDetails       = lazy(() => import('../../container/agent/ViewHappySAPDetails'));

const FollowSchedule            = lazy(() => import('../../container/agent/FollowSchedule'));
const ViewScheduledDetails      = lazy(() => import('../../container/agent/ViewScheduledDetails'));
const FollowPriority            = lazy(() => import('../../container/agent/FollowPriority'));
const ViewPriorityDetails       = lazy(() => import('../../container/agent/ViewPriorityDetails'));

const HappyEscalationList       = lazy(() => import('../../container/agent/EscalationList'));
const ScheduledEscalationList   = lazy(() => import('../../container/agent/EscalationScheduledList'));
const PriorityEscalationList    = lazy(() => import('../../container/agent/EscalationPriorityList'));
const ViewEscalation            = lazy(() => import('../../container/agent/ViewEscalation'));

const DemoHappySchedule         = lazy(() => import('../../container/agent/DemoHappySAPSchedule'));
const DemoHappyPriority         = lazy(() => import('../../container/agent/DemoHappySAPPriority'));

const MandatoryService          = lazy(() => import('../../container/agent/MandatoryService')); 
const ViewMandatoryService      = lazy(() => import('../../container/agent/ViewMandatoryService')); 

const AccessoriesTeleSalesSAP   = lazy(() => import('../../container/agent/AccessoriesTeleSalesSAP'));
const ViewAccessoriesTeleSales  = lazy(() => import('../../container/agent/ViewAccessoriesTeleSales'));

const AbandonedCart             = lazy(() => import('../../container/agent/AbandonedCart')); 
const AbandonedCartPriority     = lazy(() => import('../../container/agent/AbandonedCartPriority')); 
const ViewAbandonedCart         = lazy(() => import('../../container/agent/ViewAbandonedCart')); 

const CallRecord                = lazy(() => import('../../container/agent/CallsReport'));
const AuditQualityCallList      = lazy(() => import('../../container/agent/AuditQualityCallList'));
const ViewAuditQualityDetails   = lazy(() => import('../../container/agent/ViewAuditQualityDetails'));

const Report                    = lazy(() => import('../../container/agent/Report'));
const DownloadReport            = lazy(() => import('../../container/agent/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

const AgentLayout = ({ children }) => {

    const agentStatus = useSelector((state) => state.agentStatus.data);
    const agentStatusDetails = useSelector((state) => state.agentStatus);

    const location = useLocation();

    const isChangeCallPage = location.pathname === '/admin/agent/change_call';

    return (
        <>
            {agentStatusDetails.loading && (
                <div className="spin">
                    <Spin size="large" />
                </div>
            )}

            {!agentStatus && !isChangeCallPage && <ChangeCallDirection />}

            {(isChangeCallPage || (agentStatus && agentStatus.current_status === 'Available' && !agentStatusDetails.loading)) && children}

            {agentStatus && agentStatus.current_status !== 'Available' && !agentStatusDetails.loading && <BreakTimer />}
        </>
    );
};



function AgentRoutes() {
    const { path } = useRouteMatch();
    
    return (
        <AgentLayout>
            <Switch>
                <Route exact path={`${path}`} component={Dashboard} />
                <Route exact path={`${path}/dashboard`} component={Dashboard} />
                <Route exact path={`${path}/check_customer`} component={SearchCustomer} />
                
                <Route exact path={`${path}/change_call`} component={ ChangeCall} />

                <Route exact path={`${path}/purchase_enquiry`} component={AddPurchaseEnquiry} />
                <Route exact path={`${path}/purchase_enquiry/view`} component={ViewPurchaseDetails} />

                <Route exact path={`${path}/add_review`} component={AddReview} />
                <Route exact path={`${path}/review_list`} component={ReviewList} />
                <Route exact path={`${path}/review_list/view`} component={ViewReview} />
                
                <Route exact path={`${path}/assign_leads_walkout`} component={WalkoutList} />
                <Route exact path={`${path}/assign_leads_walkout/view`} component={WalkoutView} />

                <Route exact path={`${path}/assign_leads_purchase`} component={PurchaseEnqueriesList} />
                <Route exact path={`${path}/assign_leads_purchase/edit`} component={EditPurchaseEnquiry} />

                <Route exact path={`${path}/pai_mandatory_service`} component={MandatoryService} />
                <Route exact path={`${path}/pai_mandatory_service/view`} component={ViewMandatoryService} />

                <Route exact path={`${path}/accessories_tele_sales_sap`} component={AccessoriesTeleSalesSAP} />
                <Route exact path={`${path}/accessories_tele_sales_sap/view`} component={ViewAccessoriesTeleSales} />
                
                <Route exact path={`${path}/abandoned_cart`} component={AbandonedCart} /> 
                <Route exact path={`${path}/abandoned_cart/view`} component={ViewAbandonedCart} />  
                <Route exact path={`${path}/abandoned_cart_priority`} component={AbandonedCartPriority} /> 
                <Route exact path={`${path}/abandoned_cart_priority/view`} component={ViewAbandonedCart} /> 
            
                <Route exact path={`${path}/follow/:id`} component={RegisteredList} />
                <Route exact path={`${path}/follow/:id/view`} component={ViewDetails} />
                <Route exact path={`${path}/follow/:id/edit`} component={EditDetails} />
                
                <Route exact path={`${path}/demo_happy`} component={DemoHappy} />
                <Route exact path={`${path}/demo_happy/view`} component={ViewHappyDetails} />

                <Route exact path={`${path}/demo_happy_sap`} component={DemoHappySAP} />
                <Route exact path={`${path}/demo_happy_sap/view`} component={ViewHappySAPDetails} />
                
                <Route exact path={`${path}/complaint_happy`} component={ComplaintsHappy} />
                <Route exact path={`${path}/complaint_happy/view`} component={ViewHappyDetails} />
                
                <Route exact path={`${path}/furniture_complaint_happy`} component={FurnitureComplaintHappy} />
                <Route exact path={`${path}/furniture_complaint_happy/view`} component={ViewHappyDetails} />
                
                <Route exact path={`${path}/legal_case_happy`} component={LegalHappy} />
                <Route exact path={`${path}/legal_case_happy/view`} component={ViewHappyDetails} />

                <Route exact path={`${path}/defective_unit_delivered_happy`} component={DefectiveUnitDeliveredHappy} />
                <Route exact path={`${path}/defective_unit_delivered_happy/view`} component={ViewHappyDetails} />

                <Route exact path={`${path}/schedule_log/:id`} component={FollowSchedule} />
                <Route exact path={`${path}/schedule_log/:id/view`} component={ViewScheduledDetails} />

                <Route exact path={`${path}/priority_log/:id`} component={FollowPriority} />
                <Route exact path={`${path}/priority_log/:id/view`} component={ViewPriorityDetails} />

                <Route exact path={`${path}/sap_schedule_log`} component={DemoHappySchedule} />
                <Route exact path={`${path}/sap_schedule_log/view`} component={ViewHappySAPDetails} />
                <Route exact path={`${path}/sap_priority_log`} component={DemoHappyPriority} />
                <Route exact path={`${path}/sap_priority_log/view`} component={ViewHappySAPDetails} />

                <Route exact path={`${path}/escalation_happy`} component = {HappyEscalationList} />
                <Route exact path={`${path}/escalation_schedule_log`} component = {ScheduledEscalationList} />
                <Route exact path={`${path}/escalation_priority_log`} component = {PriorityEscalationList} />
                <Route exact path={`${path}/escalation_happy/view`} component = {ViewEscalation} />
                <Route exact path={`${path}/escalation_schedule_log/view`} component = {ViewEscalation} />
                <Route exact path={`${path}/escalation_priority_log/view`} component = {ViewEscalation} />


                <Route exact path={`${path}/search_reference`} component={SearchReference} />
                <Route exact path={`${path}/call_record`} component={CallRecord} />
                <Route exact path={`${path}/audit/:id`} component={AuditQualityCallList} />
                <Route exact path={`${path}/audit/:id/view`} component={ViewAuditQualityDetails} />
                
                <Route exact path={`${path}/report`} component={Report} />
                <Route exact path={`${path}/available_report`} component={DownloadReport} />
                <Route path="*" exact={true} component={NotFound} />
            </Switch>
        </AgentLayout>
    );
}

export default WithAdminLayout(AgentRoutes);
