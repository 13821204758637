const actions = {

    SERVICE_TYPE_LIST_BEGIN: 'SERVICE_TYPE_LIST_BEGIN',
    SERVICE_TYPE_LIST_SUCCESS: 'SERVICE_TYPE_LIST_SUCCESS',
    SERVICE_TYPE_LIST_ERR: 'SERVICE_TYPE_LIST_ERR',

    MATRIX_TYPE_LIST_BEGIN: 'MATRIX_TYPE_LIST_BEGIN',
    MATRIX_TYPE_LIST_SUCCESS: 'MATRIX_TYPE_LIST_SUCCESS',
    MATRIX_TYPE_LIST_ERR: 'MATRIX_TYPE_LIST_ERR',

    SERVICE_MATRIX_BEGIN: 'SERVICE_MATRIX_BEGIN',
    SERVICE_MATRIX_SUCCESS: 'SERVICE_MATRIX_SUCCESS',
    SERVICE_MATRIX_ERR: 'SERVICE_MATRIX_ERR',
    
    ADD_SERVICE_MATRIX_BEGIN: 'ADD_SERVICE_MATRIX_BEGIN',
    ADD_SERVICE_MATRIX_SUCCESS: 'ADD_SERVICE_MATRIX_SUCCESS',
    ADD_SERVICE_MATRIX_ERR: 'ADD_SERVICE_MATRIX_ERR',
    ADD_SERVICE_MATRIX_RESET:'ADD_SERVICE_MATRIX_RESET',

    EDIT_SERVICE_MATRIX_BEGIN: 'EDIT_SERVICE_MATRIX_BEGIN',
    EDIT_SERVICE_MATRIX_SUCCESS: 'EDIT_SERVICE_MATRIX_SUCCESS',
    EDIT_SERVICE_MATRIX_ERR: 'EDIT_SERVICE_MATRIX_ERR',
    EDIT_SERVICE_MATRIX_RESET:'EDIT_SERVICE_MATRIX_RESET',

    DELETE_SERVICE_MATRIX_BEGIN: 'DELETE_SERVICE_MATRIX_BEGIN',
    DELETE_SERVICE_MATRIX_SUCCESS: 'DELETE_SERVICE_MATRIX_SUCCESS',
    DELETE_SERVICE_MATRIX_ERR: 'DELETE_SERVICE_MATRIX_ERR',
    DELETE_SERVICE_MATRIX_RESET:'DELETE_SERVICE_MATRIX_RESET',

    SERVICE_MATRIX_DETAILS_BEGIN: 'SERVICE_MATRIX_DETAILS_BEGIN',
    SERVICE_MATRIX_DETAILS_SUCCESS: 'SERVICE_MATRIX_DETAILS_SUCCESS',
    SERVICE_MATRIX_DETAILS_ERR: 'SERVICE_MATRIX_DETAILS_ERR',

    UPLOAD_SERVICE_MATRIX_DATA_BEGIN: 'UPLOAD_SERVICE_MATRIX_DATA_BEGIN',
    UPLOAD_SERVICE_MATRIX_DATA_SUCCESS: 'UPLOAD_SERVICE_MATRIX_DATA_SUCCESS',
    UPLOAD_SERVICE_MATRIX_DATA_ERR: 'UPLOAD_SERVICE_MATRIX_DATA_ERR',
    UPLOAD_SERVICE_MATRIX_DATA_RESET: 'UPLOAD_SERVICE_MATRIX_DATA_RESET',

    UPLOAD_SERVICE_MATRIX_REPORT_DATA_BEGIN: 'UPLOAD_SERVICE_MATRIX_REPORT_DATA_BEGIN',
    UPLOAD_SERVICE_MATRIX_REPORT_DATA_SUCCESS: 'UPLOAD_SERVICE_MATRIX_REPORT_DATA_SUCCESS',
    UPLOAD_SERVICE_MATRIX_REPORT_DATA_ERR: 'UPLOAD_SERVICE_MATRIX_REPORT_DATA_ERR',


    SERVICE_MATRIX_REPORT_DATA_BEGIN : 'SERVICE_MATRIX_REPORT_DATA_BEGIN',
    SERVICE_MATRIX_REPORT_DATA_SUCCESS: 'SERVICE_MATRIX_REPORT_DATA_SUCCESS',
    SERVICE_MATRIX_REPORT_DATA_ERR : 'SERVICE_MATRIX_REPORT_DATA_ERR',
    SERVICE_MATRIX_REPORT_DATA_RESET: 'SERVICE_MATRIX_REPORT_DATA_RESET',

    SERVICE_MATRIX_REPORT_SAVE_DATA_BEGIN : 'SERVICE_MATRIX_REPORT_SAVE_DATA_BEGIN',
    SERVICE_MATRIX_REPORT_SAVE_DATA_SUCCESS: 'SERVICE_MATRIX_REPORT_SAVE_DATA_SUCCESS',
    SERVICE_MATRIX_REPORT_SAVE_DATA_ERR : 'SERVICE_MATRIX_REPORT_SAVE_DATA_ERR',

    SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_BEGIN : 'SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_BEGIN',
    SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_SUCCESS: 'SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_SUCCESS',
    SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_ERR : 'SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_ERR',


    serviceTypeListBegin: () => {
        return {
            type: actions.SERVICE_TYPE_LIST_BEGIN,
        };
    },

    serviceTypeListSuccess: (data) => {
        return {
            type: actions.SERVICE_TYPE_LIST_SUCCESS,
            data,
        };
    },

    serviceTypeListErr: (err) => {
        return {
            type: actions.SERVICE_TYPE_LIST_ERR,
            err,
        };
    },

    matrixTypeListBegin: () => {
        return {
            type: actions.MATRIX_TYPE_LIST_BEGIN,
        };
    },

    matrixTypeListSuccess: (data) => {
        return {
            type: actions.MATRIX_TYPE_LIST_SUCCESS,
            data,
        };
    },

    matrixTypeListErr: (err) => {
        return {
            type: actions.MATRIX_TYPE_LIST_ERR,
            err,
        };
    },

    
    serviceBrandMatrixBegin: () => {
        return {
            type: actions.SERVICE_MATRIX_BEGIN,
        };
    },

    serviceBrandMatrixSuccess: (data) => {
        return {
            type: actions.SERVICE_MATRIX_SUCCESS,
            data,
        };
    },

    serviceBrandMatrixErr: (err) => {
        return {
            type: actions.SERVICE_MATRIX_ERR,
            err,
        };
    },
    
    addServiceBrandMatrixBegin: () => {
        return {
            type: actions.ADD_SERVICE_MATRIX_BEGIN,
        };
    },

    addServiceBrandMatrixSuccess: (data) => {
        return {
            type: actions.ADD_SERVICE_MATRIX_SUCCESS,
            data,
        };
    },

    addServiceBrandMatrixErr: (err) => {
        return {
            type: actions.ADD_SERVICE_MATRIX_ERR,
            err,
        };
    },

    addServiceBrandMatrixReset: () => {
        return {
            type: actions.ADD_SERVICE_MATRIX_RESET,
        };
    },


    editServiceBrandMatrixBegin: () => {
        return {
            type: actions.EDIT_SERVICE_MATRIX_BEGIN,
        };
    },

    editServiceBrandMatrixSuccess: (data) => {
        return {
            type: actions.EDIT_SERVICE_MATRIX_SUCCESS,
            data,
        };
    },

    editServiceBrandMatrixErr: (err) => {
        return {
            type: actions.EDIT_SERVICE_MATRIX_ERR,
            err,
        };
    },

    editServiceBrandMatrixReset: () => {
        return {
            type: actions.EDIT_SERVICE_MATRIX_RESET,
        };
    },


    deleteServiceBrandMatrixBegin: () => {
        return {
            type: actions.DELETE_SERVICE_MATRIX_BEGIN,
        };
    },

    deleteServiceBrandMatrixSuccess: (data) => {
        return {
            type: actions.DELETE_SERVICE_MATRIX_SUCCESS,
            data,
        };
    },

    deleteServiceBrandMatrixErr: (err) => {
        return {
            type: actions.DELETE_SERVICE_MATRIX_ERR,
            err,
        };
    },

    deleteServiceBrandMatrixReset: () => {
        return {
            type: actions.DELETE_SERVICE_MATRIX_RESET,
        };
    },

    serviceBrandMatrixDetailsBegin: () => {
        return {
            type: actions.SERVICE_MATRIX_DETAILS_BEGIN,
        };
    },

    serviceBrandMatrixDetailsSuccess: (data) => {
        return {
            type: actions.SERVICE_MATRIX_DETAILS_SUCCESS,
            data,
        };
    },

    serviceBrandMatrixDetailsErr: (err) => {
        return {
            type: actions.SERVICE_MATRIX_DETAILS_ERR,
            err,
        };
    },


    uploadServiceBrandMatrixDataBegin: () => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_DATA_BEGIN,
        };
    },

    uploadServiceBrandMatrixDataSuccess: (data) => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_DATA_SUCCESS,
            data,
        };
    },

    uploadServiceBrandMatrixDataErr: (err) => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_DATA_ERR,
            err,
        };
    },

    uploadServiceBrandMatrixDataReset: () => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_DATA_RESET,
        };
    },

    uploadServiceBrandMatrixReportDataBegin: () => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_REPORT_DATA_BEGIN,
        };
    },

    uploadServiceBrandMatrixReportDataSuccess: (data) => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_REPORT_DATA_SUCCESS,
            data,
        };
    },

    uploadServiceBrandMatrixReportDataErr: (err) => {
        return {
            type: actions.UPLOAD_SERVICE_MATRIX_REPORT_DATA_ERR,
            err,
        };
    },
    
    serviceMatrixReportDataBegin: () => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_DATA_BEGIN,
        };
    },

    serviceMatrixReportDataSuccess: (data) => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_DATA_SUCCESS,
            data,
        };
    },

    serviceMatrixReportDataErr: (err) => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_DATA_ERR,
            err,
        };
    },

    serviceMatrixReportDataReset: () =>{
        return {
            type: actions.SERVICE_MATRIX_REPORT_DATA_RESET,
        };
    },

    serviceMatrixReportSaveDataBegin: () => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_SAVE_DATA_BEGIN,
        };
    },

    serviceMatrixReportSaveDataSuccess: (data) => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    serviceMatrixReportSaveDataErr: (err) => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    serviceMatrixReportDownloadDataBegin: () => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    serviceMatrixReportDownloadDataSuccess: (data) => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    serviceMatrixReportDownloadDataErr: (err) => {
        return {
            type: actions.SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
   
};

export default actions;
