import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../container/walkout/Dashboard'));
const WalkoutList       = lazy(() => import('../../container/walkout/WalkoutList'));
const WalkoutView       = lazy(() => import('../../container/walkout/ViewWalkout'));
const CategoryList      = lazy(() => import('../../container/walkout/CategoryList'));
const Report            = lazy(() => import('../../container/walkout/Report'));
const ZeroReport        = lazy(() => import('../../container/walkout/ZeroReport'));
const DownloadReport    = lazy(() => import('../../container/walkout/DownloadReport'));
const SummaryReport     = lazy(() => import('../../container/walkout/SummaryReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function MissedCustomerRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/category`} component={CategoryList} />
            <Route exact path={`${path}/un_assign_list`} component={WalkoutList} />
            <Route exact path={`${path}/assign_list`} component={WalkoutList} />
            <Route exact path={`${path}/close_list`} component={WalkoutList} />
            <Route exact path={`${path}/list`} component={WalkoutList} />
            <Route exact path={`${path}/view`} component={WalkoutView} />
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/zero_report`} component={ZeroReport} />
            <Route exact path={`${path}/available_report/:id`} component={DownloadReport} />
            <Route exact path={`${path}/summary_report`} component={SummaryReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(MissedCustomerRoutes);
