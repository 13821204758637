import actions from './actions';

const { 

    SERVICE_TYPE_LIST_BEGIN, SERVICE_TYPE_LIST_SUCCESS, SERVICE_TYPE_LIST_ERR,
    MATRIX_TYPE_LIST_BEGIN, MATRIX_TYPE_LIST_SUCCESS, MATRIX_TYPE_LIST_ERR,

    SERVICE_MATRIX_BEGIN, SERVICE_MATRIX_SUCCESS, SERVICE_MATRIX_ERR,
    ADD_SERVICE_MATRIX_BEGIN, ADD_SERVICE_MATRIX_SUCCESS, ADD_SERVICE_MATRIX_ERR, ADD_SERVICE_MATRIX_RESET,
    EDIT_SERVICE_MATRIX_BEGIN, EDIT_SERVICE_MATRIX_SUCCESS, EDIT_SERVICE_MATRIX_ERR, EDIT_SERVICE_MATRIX_RESET,
    DELETE_SERVICE_MATRIX_BEGIN, DELETE_SERVICE_MATRIX_SUCCESS, DELETE_SERVICE_MATRIX_ERR, DELETE_SERVICE_MATRIX_RESET,
    SERVICE_MATRIX_DETAILS_BEGIN, SERVICE_MATRIX_DETAILS_SUCCESS, SERVICE_MATRIX_DETAILS_ERR,


    UPLOAD_SERVICE_MATRIX_DATA_BEGIN, UPLOAD_SERVICE_MATRIX_DATA_SUCCESS, UPLOAD_SERVICE_MATRIX_DATA_ERR, UPLOAD_SERVICE_MATRIX_DATA_RESET,
    UPLOAD_SERVICE_MATRIX_REPORT_DATA_BEGIN, UPLOAD_SERVICE_MATRIX_REPORT_DATA_SUCCESS, UPLOAD_SERVICE_MATRIX_REPORT_DATA_ERR,


    SERVICE_MATRIX_REPORT_DATA_BEGIN, SERVICE_MATRIX_REPORT_DATA_SUCCESS, SERVICE_MATRIX_REPORT_DATA_ERR, SERVICE_MATRIX_REPORT_DATA_RESET,
    SERVICE_MATRIX_REPORT_SAVE_DATA_BEGIN, SERVICE_MATRIX_REPORT_SAVE_DATA_SUCCESS, SERVICE_MATRIX_REPORT_SAVE_DATA_ERR, 
    SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_BEGIN, SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_SUCCESS, SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_ERR, 

} = actions;


const initialStateFilter = { data: '', loading: false, error: null, };

const initialState = { data: [], loading: false, error: null, };


const serviceTypeListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SERVICE_TYPE_LIST_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SERVICE_TYPE_LIST_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SERVICE_TYPE_LIST_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const matrixTypeListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MATRIX_TYPE_LIST_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MATRIX_TYPE_LIST_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MATRIX_TYPE_LIST_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const serviceMatrixReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SERVICE_MATRIX_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SERVICE_MATRIX_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SERVICE_MATRIX_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const addServiceMatrixReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ADD_SERVICE_MATRIX_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ADD_SERVICE_MATRIX_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ADD_SERVICE_MATRIX_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ADD_SERVICE_MATRIX_RESET:
            return initialState;
        default:
            return state;
    }
};

const editServiceMatrixReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EDIT_SERVICE_MATRIX_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EDIT_SERVICE_MATRIX_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EDIT_SERVICE_MATRIX_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case EDIT_SERVICE_MATRIX_RESET:
            return initialState;
        default:
            return state;
    }
};

const deleteServiceMatrixReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DELETE_SERVICE_MATRIX_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DELETE_SERVICE_MATRIX_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DELETE_SERVICE_MATRIX_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case DELETE_SERVICE_MATRIX_RESET:
            return initialState;
        default:
            return state;
    }
};

const serviceMatrixDetailsReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SERVICE_MATRIX_DETAILS_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SERVICE_MATRIX_DETAILS_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SERVICE_MATRIX_DETAILS_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};


const uploadServiceMatrixReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPLOAD_SERVICE_MATRIX_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPLOAD_SERVICE_MATRIX_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPLOAD_SERVICE_MATRIX_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPLOAD_SERVICE_MATRIX_DATA_RESET:
            return initialState

        default:
            return state;
    }
};

const uploadServiceMatrixReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPLOAD_SERVICE_MATRIX_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPLOAD_SERVICE_MATRIX_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPLOAD_SERVICE_MATRIX_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const serviceMatrixReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SERVICE_MATRIX_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SERVICE_MATRIX_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SERVICE_MATRIX_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case SERVICE_MATRIX_REPORT_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const serviceMatrixReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SERVICE_MATRIX_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SERVICE_MATRIX_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SERVICE_MATRIX_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const serviceMatrixReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SERVICE_MATRIX_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { 
    serviceTypeListReducers, matrixTypeListReducers, serviceMatrixReducers, addServiceMatrixReducers, editServiceMatrixReducers, deleteServiceMatrixReducers,
    serviceMatrixDetailsReducers, 
    uploadServiceMatrixReducers, uploadServiceMatrixReportReducers,
    serviceMatrixReportReducers, serviceMatrixReportSaveReducers, serviceMatrixReportDownloadReducers
};
