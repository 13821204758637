import React from 'react';
import { Main} from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { ChangeCallStatus } from '../../components/agent/changeCall';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../components/buttons/buttons';
import ManualDialPad from '../../components/agent/manualDialPad';
import BreakTimer from './BreakTimer';


const ChangeCallDirection = () => {


	const agentStatus = useSelector((state) => state.agentStatus.data);

	const agentStatusDetails = useSelector((state) => state.agentStatus);
	

    return (
		<>
			<PageHeader ghost title="Change Call Direction"
				buttons={[
					<div key="1" className="page-header-actions">
						{agentStatus?.type === 'out-bound' &&  agentStatus?.lead_type === 'assign_leads' && (
							<Button size="small" type="primary">
								<FeatherIcon icon="phone" size={14} /> Out Bound Call {agentStatus.mobile}
							</Button>
						)}

						{agentStatus?.type === 'out-bound' &&  agentStatus?.lead_type === 'dial_out' && (
							<Button size="small" type="primary">
								<FeatherIcon icon="phone" size={14} /> Out Bound Call PRI  {agentStatus?.line === 'Aritel-1' && ('080-42464343') } { agentStatus?.line === 'Aritel-2' &&  ('080-44355340')  }
							</Button>
						)}

						{ agentStatus && ( <ManualDialPad /> )}

					</div>,
				]}
			/>
			<Main>
				<ChangeCallStatus />
			</Main>
		</>
	
  	)
}

export default ChangeCallDirection

