import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Row, Col, Skeleton, Form, Modal, Input, Select, Radio } from 'antd';
import { Link } from 'react-router-dom';
import { Cards } from '../cards/frame/cards-frame';
import Heading from '../heading/heading';
import { Button } from '../buttons/buttons';
import { UsercardWrapper, UserCard } from '../../container/agent/style';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentCurrentStatus, updateAgentCallStatus, getAgentNumber, resetAgentCallStatus, getSIPNumber } from '../../redux/agent/actionCreator';
import FeatherIcon from 'feather-icons-react';

const { Option } = Select;

export const ChangeCallStatus = () => {

    const dispatch = useDispatch();

    const [changeform]  = Form.useForm();
    const [inboundForm] = Form.useForm();

    useEffect(() => {
        if (getAgentCurrentStatus) {
            dispatch(getAgentCurrentStatus());
            dispatch(getAgentNumber());
            dispatch(getSIPNumber());
        }
    }, [dispatch]);

    const agentStatus = useSelector((state) => state.agentStatus.data);

    const agentNumber = useSelector((state) => state.agentNumber.data);

    const sipNumber = useSelector((state) => state.sipNumber.data);

    const [visible, setVisible] = useState(false);

    const [selectedValue, setSelectedValue] = useState(null);

    const [visibleINSIP,setVisibleINSIP] = useState(false);

    const onChangeStatus = useCallback((type,status) => {
        setSelectedValue(null);
        if(type && status){
            if(type === 'out-bound' && status === 'in'){
                dispatch(getAgentNumber());
                const dynamicValues = {
                    type: type,
                    login: status,
                };
                changeform.setFieldsValue(dynamicValues);
                setVisibleINSIP(false);
                setVisible(true);
            } else if(type === 'in-bound' && status === 'in') { 
                const dynamicValues = {
                    type: type,
                    login: status,
                };
                inboundForm.setFieldsValue(dynamicValues);
                setVisible(false);
                setVisibleINSIP(true);
            } else {
                dispatch(updateAgentCallStatus({type:type, login:status}))
            }
        }  
       // eslint-disable-next-line 
    }, [dispatch,agentStatus]);

    const handleChange = (value) => {
        setSelectedValue(value);
    };
    
     

    const handleOk = () => {
        changeform.validateFields().then((values) => {
		    dispatch(updateAgentCallStatus(values))
            // After successful submission, close the modal
            setVisible(false);
		}).catch((errorInfo) => {
            
        });
    }

    const handleCancel = () => {
		setVisible(false);
	};


    const handleSIPOk = () => {
        inboundForm.validateFields().then((values) => {
		    dispatch(updateAgentCallStatus(values))
            // After successful submission, close the modal
            setVisibleINSIP(false);
		}).catch((errorInfo) => {
            
        });
    }

    const handleSIPCancel = () => {
		setShowSIPInput(false);
	};

    const [showSIPInput, setShowSIPInput] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const onRadioChange = (e) => {
        setShowInput(e.target.value === 'assign_leads');
        setShowSIPInput(e.target.value === 'dial_out');
    }
    
    

    const agentSubmitCallStatusData = useSelector((state) => state.agentSubmitCallStatus);

    useEffect(() => {
               
        if(agentSubmitCallStatusData?.data?.data === 'submitted' && agentSubmitCallStatusData?.loading === false){
            Modal.info({
                title: agentSubmitCallStatusData?.data?.message,
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    dispatch(resetAgentCallStatus());
                },
            });
        }

        if(agentSubmitCallStatusData?.error  && agentSubmitCallStatusData?.loading === false){
            Modal.error({
                title: 'Please try Again',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    dispatch(resetAgentCallStatus());
                },
            });
        }

    },[agentSubmitCallStatusData, dispatch])

    return (
    
        <UsercardWrapper>
            <Row gutter={25}>

                <Col xxl={6} xl={8} sm={12} xs={24}>
                    <Suspense
                        fallback={
                            <Cards headless>
                                <Skeleton avatar active />
                            </Cards>
                        }
                    >
                        <UserCard>
                            <div className="card user-card theme-grid-2">
                                <Cards headless>
                                    <figure>
                                        <div className="user-card__img">
                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="send" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2a15.99 15.99 0 00-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-.9 3.7-.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 .7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-.8 4.2-2.6 5-5 1.4-4.2-.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg>
                                        </div>
                                        <figcaption>
                                            <div className="user-card__bg" style={{ height: '150px', background: `url(${require('../../static/img/agent/1.png')}) `, }} >
                                                <div className='card_info_txt'>
                                                    <h5>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-calling" width="22" height="22" viewBox="0 0 24 24" strokeidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path><path d="M15 7l0 .01"></path><path d="M18 7l0 .01"></path><path d="M21 7l0 .01"></path></svg>
                                                        </span>
                                                        Inbound
                                                    </h5>
                                                    <p>Access inbound calls only</p>
                                                </div>
                                            </div>
                                            <div className="card__bottom">
                                                <div className="card__content">
                                                    <Heading className="card__name" as="h6">
                                                        <Link to="#">To Receive the call and to solve the queries to the customer problem</Link>
                                                    </Heading>
                                                </div>

                                                <div className="card__actions">
                                                    { agentStatus && (agentStatus.type === 'in-bound') ?
                                                        <Button size="default" className="ant-btn-primary active" type="white" onClick={ () => onChangeStatus('in-bound','out')} disabled={ agentStatus && agentStatus.type === 'in-bound' ? false : true } >
                                                            <FeatherIcon icon="log-out" size={20} /> End Inbound
                                                        </Button>
                                                        :
                                                        <Button size="default" type="white" onClick={ () => onChangeStatus('in-bound','in')} disabled={ agentStatus && agentStatus.type === 'in-bound' ? true : false }>
                                                            <FeatherIcon icon="log-in" size={20} /> Start Inbound
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </Cards>
                            </div>
                        </UserCard>
                    </Suspense>
                </Col>

                <Col xxl={6} xl={8} sm={12} xs={24}>
                    <Suspense
                        fallback={
                            <Cards headless>
                                <Skeleton avatar active />
                            </Cards>
                        }
                    >
                        <UserCard>
                            <div className="card user-card theme-grid-2">
                                <Cards headless>
                                    <figure>
                                        <div className="user-card__img">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-pause" width="30" height="30" viewBox="0 0 24 24" strokeidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path><path d="M20 3l0 4"></path><path d="M16 3l0 4"></path></svg>
                                        </div>

                                        <figcaption>
                                            <div className="user-card__bg" style={{ height: '150px', background: `url(${require('../../static/img/agent/2.png')})`, }} >
                                                <div className='card_info_txt'>
                                                    <h5>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-calling" width="22" height="22" viewBox="0 0 24 24" strokeidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path><path d="M15 7l0 .01"></path><path d="M18 7l0 .01"></path><path d="M21 7l0 .01"></path></svg>
                                                        </span>
                                                        Outbound
                                                    </h5>
                                                    <p>Access outbound calls only</p>
                                                </div>
                                            </div>
                                            <div className="card__bottom">
                                                <div className="card__content">
                                                    <Heading className="card__name" as="h6">
                                                        <Link to="#">To Receive the call and to solve the queries to the customer problem</Link>
                                                    </Heading>
                                                </div>

                                                <div className="card__actions">
                                                    { agentStatus &&  (agentStatus.type === 'out-bound') ?
                                                        <Button size="default" className="ant-btn-primary active"  type="white" onClick={ () => onChangeStatus('out-bound','out')} disabled={ agentStatus && agentStatus.type ==='out-bound' ? false : true } >
                                                            <FeatherIcon icon="log-out" size={20} /> End Outbound
                                                        </Button>
                                                        :
                                                        <Button size="default" type="white" onClick={ () => onChangeStatus('out-bound','in')} disabled={ agentStatus && agentStatus.type ==='out-bound' ? true : false }  >
                                                            <FeatherIcon icon="log-in" size={20} /> Start Outbound
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </Cards>
                            </div>
                        </UserCard>
                    </Suspense>
                </Col>
            </Row>

            <Modal open={visible} onOk={handleOk} onCancel={handleCancel} >
                <Form form={changeform} layout="vertical">
                    
                    <Form.Item name="lead_type" label="Select an Type" rules={[{ required: true, message: 'Please select an option!' }]} >
                        <Radio.Group onChange={onRadioChange}>
                            <Radio value="assign_leads">Assign Leads</Radio>
                            <Radio value="dial_out">Dial Out</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {showInput && (
                        <Form.Item name="mobile" label="Extension Mobile Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'please select the mobile number',
                                },
                            ]}
                        >
                            <Select style={{ width: '100%' }} 
                                placeholder="Please choose the Extension Mobile Number" 
                                value={selectedValue}
                                options={agentNumber && agentNumber.object_list.map(data => ({ label: data.mobile, value: data.mobile, disabled: (data.status === true && data.is_available === true ) ? false : true }))}
                                optionFilterProp="children"
                                filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                showSearch
                                showArrow={true}
                                notFoundContent={null}
                                onChange={handleChange}
                                allowClear

                            >
                            </Select>
                        </Form.Item>
                    )}

                    { showSIPInput && (
                        <Form.Item name="line" label="SIP Extension Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'please select the SIP Extension Number',
                                },
                            ]}
                        >
                            {/* <Select placeholder="Please choose the "  allowClear>
                                <Option value="Airtel-1">080-42464343 (Airtel -1) </Option>
                                <Option value="Airtel-2">080-44355340 (Airtel -2) </Option>
                                <Option value="Airtel-3">040-45177300 (Airtel -3) </Option>
                            </Select> */}


                            <Select style={{ width: '100%' }} 
                                placeholder="Please choose the SIP Number" 
                                value={selectedValue}
                                options={sipNumber.length > 0 && sipNumber.map(data => ({ label: data.name, value: data.number+'__'+data.line, }))}
                                optionFilterProp="children"
                                filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                showSearch
                                showArrow={true}
                                notFoundContent={null}
                                onChange={handleChange}
                                allowClear

                            ></Select>
                        </Form.Item>
                    )}
                    
                    <Form.Item name="type" initialValue={''} noStyle>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item name="login" initialValue={''} noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal>


            <Modal open={visibleINSIP} onOk={handleSIPOk} onCancel={handleSIPCancel} >
                <Form form={inboundForm} layout="vertical">
                    
                    
                    <Form.Item name="line" label="SIP Extension Number"
                        rules={[
                            {
                                required: true,
                                message: 'please select the SIP Extension Number',
                            },
                        ]}
                    >
                        <Select style={{ width: '100%' }} 
                            placeholder="Please choose the SIP Number" 
                            value={selectedValue}
                            options={sipNumber.length > 0 && sipNumber.map(data => ({ label: data.name, value: data.number+'__'+data.line, }))}
                            optionFilterProp="children"
                            filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            showSearch
                            showArrow={true}
                            notFoundContent={null}
                            onChange={handleChange}
                            allowClear

                        ></Select>
                    </Form.Item>
                
                    
                    <Form.Item name="type" initialValue={''} noStyle>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item name="login" initialValue={''} noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal>
        </UsercardWrapper>
    )
}

