import React, { useEffect, useState} from 'react';
import { Row, Col , Modal} from 'antd';
import { Main} from '../styled';
import FeatherIcon from 'feather-icons-react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { ComingsoonStyleWrapper } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { clearStopTimer, resetAgentCallStatus, resetStopTimer } from '../../redux/agent/actionCreator';
import {  useHistory  } from 'react-router-dom';

const { Title } = Typography;

const BreakTimer = () => {

	const dispatch = useDispatch();

	let history = useHistory();

	const agentStatus = useSelector((state) => state.agentStatus.data);

	// Timer duration in seconds (10 minutes)
	const [timerDuration,setTimerDuration] = useState('0');

	useEffect(() => { 
		var TIMER_DURATION = 0; 
        if(agentStatus?.current_status){
            switch (agentStatus?.current_status) {
                case 'Unavailable':
                    TIMER_DURATION = 300
                    break;
				case 'Short-Break':
					TIMER_DURATION = 600;
					break;
                case 'Tea-Break':
                    TIMER_DURATION = 900;
                    break;
                case 'Lunch':
                    TIMER_DURATION = 2700;
                    break;
                case 'Meeting':
                    TIMER_DURATION = 600;
                    break;
                case 'Training':
                    TIMER_DURATION = 600;
                    break;
                default:
                    TIMER_DURATION = 600;
                    break;
            }
			setTimerDuration(TIMER_DURATION);
        }
    },[agentStatus])



	// Get the current time in seconds
	const currentTime = Math.floor(Date.now() / 1000);

	const startTime = agentStatus?.current_timeline?.start ?  Math.floor(new Date(agentStatus?.current_timeline?.start).getTime()/1000) : currentTime;

	const calculateTimeLeft = () => {
	 	const elapsed = Math.floor(Date.now() / 1000) - startTime;
		// const remaining = TIMER_DURATION - elapsed;
	  	return Math.max(elapsed, 0); // Ensure the time does not go below zero
		// return remaining;
	};
  
	const [time, setTime] = useState(calculateTimeLeft);
  
	useEffect(() => {
	  	const timer = setInterval(() => {
			setTime(calculateTimeLeft);
	  	}, 1000);
  
	  	return () => clearInterval(timer); 
		// eslint-disable-next-line
	}, [startTime]); 
  
	const formatTime = (seconds) => {
	  	const minutes = Math.floor(seconds / 60);
	  	const remainingSeconds = seconds % 60;
	  	return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

	const clearTimer = () => {
		dispatch(clearStopTimer());
	}
	
	const [hasExceeded, setHasExceeded] = useState(false);
	useEffect(() => {
		const hasExceededCheck =  timerDuration <= time;
		if(hasExceededCheck === true){
			setHasExceeded(hasExceededCheck);
		}
	},[time, timerDuration])


	const clearStopTimerData =  useSelector((state) => state.stopTimer);

	useEffect(() => {
        if(clearStopTimerData?.data === 'submitted' && clearStopTimerData?.loading === false){
            Modal.info({
                title: 'Timer Stopped Successfully',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    dispatch(resetStopTimer());
					dispatch(resetAgentCallStatus())
                    history.goBack();
                  // Add your custom action here
                },
            });
        }

        if(clearStopTimerData?.error  && clearStopTimerData?.loading === false){
            Modal.error({
                title: 'Please try Again',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    dispatch(resetStopTimer());
                    history.goBack();
                  // Add your custom action here
                },
            });
        }

    },[clearStopTimerData, dispatch])
  
	return (
		<>
			<PageHeader ghost title="Break Timer" />
			<Main>
				<Row gutter={25}>
					<Col sm={24} xs={24}>
						<ComingsoonStyleWrapper>
							<Cards headless>
								<div className="coming-soon-content">
									<Title level={2}> Please take a {agentStatus?.current_status.replace('-',' ').toLowerCase() } for  {Math.floor(timerDuration / 60)} minutes.</Title>
									<p> It's {agentStatus?.current_status.replace('-',' ').toLowerCase() } time! Take a moment to relax. </p>
									<Title level={3} className={hasExceeded ? 'timer-red' : ''} >Time : {formatTime(time)}</Title>
								</div>

								<Button  type="danger"  onClick={() => clearTimer()}>
                                    <FeatherIcon icon="stop-circle" size={16} />  Stop
                                </Button>
							</Cards>
						</ComingsoonStyleWrapper>
					</Col>
				</Row>
			</Main>
		</>
	);
};
  

export default BreakTimer

