import actions from './actions';

const { 
    PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN, PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS, PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_ERR,
    PURCHASE_DASHBOARD_CATEGORY_DATA_BEGIN, PURCHASE_DASHBOARD_CATEGORY_DATA_SUCCESS, PURCHASE_DASHBOARD_CATEGORY_DATA_ERR,
    PURCHASE_DASHBOARD_TICKET_DATA_BEGIN, PURCHASE_DASHBOARD_TICKET_DATA_SUCCESS, PURCHASE_DASHBOARD_TICKET_DATA_ERR,
    PURCHASE_DASHBOARD_SUMMARY_DATA_BEGIN, PURCHASE_DASHBOARD_SUMMARY_DATA_SUCCESS, PURCHASE_DASHBOARD_SUMMARY_DATA_ERR,
    PURCHASE_DASHBOARD_REGION_DATA_BEGIN, PURCHASE_DASHBOARD_REGION_DATA_SUCCESS, PURCHASE_DASHBOARD_REGION_DATA_ERR,
    PURCHASE_QUERIES_DATA_BEGIN, PURCHASE_QUERIES_DATA_SUCCESS, PURCHASE_QUERIES_DATA_ERR, 
    PURCHASE_QUERIES_DETAILS_DATA_BEGIN,PURCHASE_QUERIES_DETAILS_DATA_SUCCESS,PURCHASE_QUERIES_DETAILS_DATA_ERR,

    PURCHASE_QUERIES_STATUS_DATA_BEGIN, PURCHASE_QUERIES_STATUS_DATA_SUCCESS, PURCHASE_QUERIES_STATUS_DATA_ERR, 
    PURCHASE_REASON_DATA_BEGIN, PURCHASE_REASON_DATA_SUCCESS, PURCHASE_REASON_DATA_ERR, 
    PURCHASE_LEAD_SOURCE_DATA_BEGIN, PURCHASE_LEAD_SOURCE_DATA_SUCCESS, PURCHASE_LEAD_SOURCE_DATA_ERR, 
    PURCHASE_HISTORY_DATA_BEGIN, PURCHASE_HISTORY_DATA_SUCCESS, PURCHASE_HISTORY_DATA_ERR, 
    RECHECK_PURCHASE_HISTORY_DATA_BEGIN, RECHECK_PURCHASE_HISTORY_DATA_SUCCESS, RECHECK_PURCHASE_HISTORY_DATA_ERR, 
    PURCHASE_REPORT_DATA_BEGIN, PURCHASE_REPORT_DATA_SUCCESS, PURCHASE_REPORT_DATA_ERR,
    PURCHASE_REPORT_SAVE_DATA_BEGIN, PURCHASE_REPORT_SAVE_DATA_SUCCESS, PURCHASE_REPORT_SAVE_DATA_ERR, 
    PURCHASE_REPORT_DOWNLOAD_DATA_BEGIN, PURCHASE_REPORT_DOWNLOAD_DATA_SUCCESS, PURCHASE_REPORT_DOWNLOAD_DATA_ERR, 
    PURCHASE_ADD_NOTES_DATA_BEGIN,PURCHASE_ADD_NOTES_DATA_SUCCESS,PURCHASE_ADD_NOTES_DATA_ERR, } = actions;

const initialStateFilter = { data: [], loading: false, error: null, };

const initialStateDashboard = { data: [], start:null, end:null, loading: false, error: null, };

const purchaseDashbaordLeadSourceReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case PURCHASE_DASHBOARD_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseDashboardCategoryReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case PURCHASE_DASHBOARD_CATEGORY_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case PURCHASE_DASHBOARD_CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case PURCHASE_DASHBOARD_CATEGORY_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseDashboardTicketReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case PURCHASE_DASHBOARD_TICKET_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case PURCHASE_DASHBOARD_TICKET_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case PURCHASE_DASHBOARD_TICKET_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseDashbaordSummaryReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_DASHBOARD_SUMMARY_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_DASHBOARD_SUMMARY_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_DASHBOARD_SUMMARY_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseDashbaordRegionReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case PURCHASE_DASHBOARD_REGION_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_DASHBOARD_REGION_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                start,
                end,
                loading: false,
            };
        case PURCHASE_DASHBOARD_REGION_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseEnquiresReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_QUERIES_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_QUERIES_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_QUERIES_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


const purchaseEnquiresDetailsReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_QUERIES_DETAILS_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_QUERIES_DETAILS_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_QUERIES_DETAILS_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseEnquiresStatusReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_QUERIES_STATUS_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_QUERIES_STATUS_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_QUERIES_STATUS_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseReasonReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_REASON_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_REASON_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_REASON_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseLeadSourceReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseHistoryReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_HISTORY_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_HISTORY_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_HISTORY_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const recheckPurchaseHistoryReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case RECHECK_PURCHASE_HISTORY_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case RECHECK_PURCHASE_HISTORY_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case RECHECK_PURCHASE_HISTORY_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseAddNotesReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_ADD_NOTES_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_ADD_NOTES_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_ADD_NOTES_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseReportReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_REPORT_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_REPORT_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_REPORT_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseReportSaveReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_REPORT_SAVE_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const purchaseReportDownloadReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case PURCHASE_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case PURCHASE_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
            };
        case PURCHASE_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { 
    purchaseDashbaordLeadSourceReducers, purchaseDashboardCategoryReducers, purchaseDashboardTicketReducers, purchaseDashbaordSummaryReducers, purchaseDashbaordRegionReducers, 
    purchaseEnquiresReducers, purchaseEnquiresDetailsReducers, purchaseEnquiresStatusReducers, purchaseReasonReducers, purchaseLeadSourceReducers, 
    purchaseHistoryReducers, recheckPurchaseHistoryReducers,
    purchaseAddNotesReducers, purchaseReportReducers, purchaseReportSaveReducers, purchaseReportDownloadReducers 
};
